import {useMemo, useState, useLayoutEffect, useRef, Ref} from 'react';

import {isNotNil} from 'ramda-adjunct';

import {debounce, ReadonlyOrWritable} from 'shared';

export type ElementSize = readonly [number | null, number | null];
export type UseElementSizeOptions = {
  debounce?: number;
};

/**
 * @about Observes width and height of DOM element and returns new dimensions it changes
 * @returns [ref, width, height]
 *
 * @example
 * const [ref, width, height] = useElementSize<HTMLDivElement>();
 *
 * console.log('Width of the div bellow is: ', width)
 * <div ref={ref}>Example</div>
 */
export const useElementSize = <T extends Element = Element>(
  options?: UseElementSizeOptions
): [Ref<T>, number | null, number | null] => {
  const ref = useRef<T>(null);
  const nodeRef = useRef<Element | null>(null);
  const [size, setSize] = useState<ElementSize>([null, null]);

  const setSizeDebounced = useMemo(
    () => (isNotNil(options?.debounce) ? debounce(setSize, options?.debounce ?? 0, true) : setSize),
    [options?.debounce]
  );

  useLayoutEffect(() => {
    nodeRef.current = ref.current;

    function callback(entries: ResizeObserverEntry[]) {
      window.requestAnimationFrame(() => {
        const entry = entries[0];
        if (entry?.contentBoxSize) {
          const contentBoxSize = getContentBoxSize(entry.contentBoxSize);
          return setSizeDebounced([contentBoxSize.inlineSize, contentBoxSize.blockSize]);
        }
        if (entry?.contentRect) {
          return setSize([entry.contentRect.width, entry.contentRect.height]);
        }
        setSize([null, null]);
      });
    }

    const resizeObserver = new ResizeObserver(callback);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }
    return () => void (!!nodeRef.current && resizeObserver.unobserve(nodeRef.current));
  }, [setSizeDebounced]);

  return [ref, size[0], size[1]];
};

const isArray = (value: unknown): value is any[] => Array.isArray(value);

/**
 * @about Firefox implements `contentBoxSize` as a single content rect, rather than an array
 */
const getContentBoxSize = <T extends ResizeObserverSize | ResizeObserverSize[]>(
  size: ReadonlyOrWritable<T>
): T extends Array<infer U> ? U : T => (isArray(size) ? size[0] : size);

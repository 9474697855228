import {useMemo, useRef, useEffect} from 'react';

import {isFunction, isNumber} from 'ramda-adjunct';

import {TestIdProps, useOnMount} from 'shared';

import {Alert} from '../../Alert/Alert';
import {useTranslationContext} from '../../TranslationProvider/TranslationContext';
import {NotificationProps} from '../Notification.types';
import {getAutoClose} from '../utils/getAutoClose';
import {getDefaultMessage} from '../utils/getDefaultMessage';

export interface NotificationContainerProps extends TestIdProps {
  notification: NotificationProps;
  onHide(id: string): void;
  autoClose: false | number;
}

export function NotificationContainer(props: NotificationContainerProps) {
  const {notification, autoClose, onHide} = props;
  const {variant, message} = notification;
  const t = useTranslationContext();
  const defaultMessage = getDefaultMessage(variant, t);

  const {autoClose: notificationAutoClose} = notification;
  const autoCloseTimeout = getAutoClose(autoClose, notificationAutoClose);
  const hideTimeout = useRef<number>(null);

  const handleHide = () => {
    onHide(notification.id as string);
    if (hideTimeout.current) {
      window.clearTimeout(hideTimeout.current);
    }
  };

  const cancelDelayedHide = () => {
    if (hideTimeout.current) {
      clearTimeout(hideTimeout.current);
    }
  };

  const handleDelayedHide = () => {
    if (isNumber(autoCloseTimeout)) {
      hideTimeout.current = window.setTimeout(handleHide, autoCloseTimeout);
    }
  };

  useOnMount(() => {
    if (isFunction(notification.onOpen)) {
      notification.onOpen(notification);
    }
  });

  useEffect(() => {
    handleDelayedHide();
    return cancelDelayedHide;
  }, [autoClose, notification.autoClose]);

  const alertConfig = useMemo(
    () => ({
      ...notification,
      message: message || defaultMessage,
    }),
    [notification, defaultMessage]
  );

  return (
    <div onMouseEnter={cancelDelayedHide} onMouseLeave={handleDelayedHide}>
      <Alert {...alertConfig} onClose={handleHide} />
    </div>
  );
}

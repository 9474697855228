import {CSSProperties, ReactNode, RefObject} from 'react';

import {isFalse} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

export interface AnimatedPopperProps extends TestIdProps {
  isOpen: boolean;
  children?: ReactNode;
  clickRef?: RefObject<HTMLDivElement | null>;
  floatingRef?: (node: HTMLElement | null) => void;
  floatingStyles: CSSProperties;
  transitionStyles: CSSProperties;
}

export function AnimatedPopper(props: AnimatedPopperProps) {
  if (isFalse(props.isOpen)) {
    return;
  }

  return (
    <div
      ref={props.floatingRef}
      data-testid={suffixTestId('animatedPopperContent', props)}
      style={{...props.floatingStyles, zIndex: 999}}
    >
      <div ref={props.clickRef} style={props.transitionStyles}>
        {props.children}
      </div>
    </div>
  );
}

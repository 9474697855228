import styled, {css, keyframes} from 'styled-components';
import {match} from 'ts-pattern';

import {useEffect, useRef, useState} from 'react';

import {always} from 'ramda';

import {RequiredTestIdProps, suffixTestId} from 'shared';

type ButtonVariant = 'success';

export interface TimedProgressButtonProps extends RequiredTestIdProps {
  label: string;
  variant: ButtonVariant;
  durationInMs?: number;
  onClick?: () => void;
  onProgressEnd?: () => void;
}

export function TimedProgressButton(props: TimedProgressButtonProps) {
  const durationInMs = props.durationInMs ?? 2000;
  const [isHovered, setIsHovered] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>(null);
  const startTimeRef = useRef<number>(null);
  const remainingTimeRef = useRef<number>(durationInMs);

  const {onProgressEnd} = props;
  useEffect(() => {
    if (!isHovered && remainingTimeRef.current > 0) {
      startTimeRef.current = Date.now();
      timerRef.current = setTimeout(() => {
        onProgressEnd?.();
        remainingTimeRef.current = 0;
      }, remainingTimeRef.current);
    } else {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        if (startTimeRef.current) {
          const elapsed = Date.now() - startTimeRef.current;
          remainingTimeRef.current = Math.max(remainingTimeRef.current - elapsed, 0);
        }
      }
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isHovered, durationInMs, onProgressEnd]);

  useEffect(() => {
    remainingTimeRef.current = durationInMs;
  }, [durationInMs]);

  return (
    <StyledButton
      $variant={props.variant}
      $durationInMs={durationInMs}
      data-testid={suffixTestId('timedProgressButton', props)}
      onClick={props.onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span
        css={css`
          z-index: ${({theme}) => theme.zIndices.CONTENT};
        `}
      >
        {props.label}
      </span>
    </StyledButton>
  );
}

// getSize with keyframes is not working properly
// eslint-disable-next-line eag/no-css-property
const progressAnimation = keyframes`
  0% {
    width: 0;
    border-radius: 8px 0 0 8px;
  }
  99% {
    width: 99%;
    border-radius: 8px 0 0 8px;
  }
  100% {
    width: 100%;
    border-radius: 8px;
  }
`;

const getVariantStyles = (variant: ButtonVariant) =>
  match(variant)
    .with(
      'success',
      always(css`
        color: ${({theme}) => theme.colors.general.white};
        background-color: ${({theme}) => theme.colors.palettes.green[80][100]};

        &:hover {
          background-color: ${({theme}) => theme.colors.palettes.green[80][100]};
          &::after {
            background-color: ${({theme}) => theme.colors.palettes.green[80][100]};
          }
        }

        &::after {
          background-color: ${({theme}) => theme.colors.severity.success};
        }
      `)
    )
    .exhaustive();

interface StyledButtonProps {
  $variant: 'success';
  $durationInMs: number;
}
// eslint-disable-next-line eag/no-css-property
const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${({theme}) => theme.fontSizes.text.small};
  font-weight: ${({theme}) => theme.fontWeights.regular};
  line-height: ${({theme}) => theme.lineHeights.text.small};
  border-radius: ${({theme}) => theme.getSize(2)};
  padding: ${({theme}) => `${theme.getSize(2)} ${theme.getSize(4)}`};
  letter-spacing: 0;
  white-space: nowrap;
  transition: all 250ms;
  min-width: 100%;

  &:focus-visible {
    outline: none;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    animation: ${progressAnimation} ${({$durationInMs: $duration}) => `${$duration}ms`} linear
      forwards;
  }

  &:hover::after {
    animation-play-state: paused;
  }

  ${({$variant}) => getVariantStyles($variant)}
`;

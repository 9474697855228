import {AnimatePresence, motion} from 'framer-motion';
import {Portal, useScrollLock} from 'platform/components';
import {Box, Show} from 'platform/foundation';

import {MOBILE_DRAWER} from '../../consts/styles';

const MotionBox = motion.create(Box);

interface MobileDrawerProps {
  isVisible: boolean;
  children: React.ReactNode;
}

const drawerVariants = {
  open: {
    x: 0,
    transition: {
      duration: MOBILE_DRAWER.ANIMATION_DURATION,
      ease: 'easeInOut',
    },
  },
  closed: {
    x: '100%',
    transition: {
      duration: 0.2,
      ease: 'easeInOut',
    },
  },
} as const;

export function MobileDrawer(props: MobileDrawerProps) {
  useScrollLock({autoLock: props.isVisible});

  return (
    <Portal>
      <Show when={props.isVisible}>
        {/* eslint-disable-next-line react/forbid-dom-props */}
        <div className={MOBILE_DRAWER.VISIBLE_CLASS_NAME}></div>
      </Show>
      <AnimatePresence>
        {props.isVisible && (
          <MotionBox
            position="fixed"
            top={0}
            left={0}
            width="100vw"
            height="100%"
            zIndex="MOBILE_NAVIGATION"
            variants={drawerVariants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <Box
              width="100%"
              height="100%"
              padding={6}
              backgroundColor="general.white"
              overflowY="auto"
            >
              {props.children}
            </Box>
          </MotionBox>
        )}
      </AnimatePresence>
    </Portal>
  );
}

import FallbackIcon from './fallback.svg';
import ArrowDown from './Icon_ArrowDown.svg';
import ArrowLeft from './Icon_ArrowLeft.svg';
import ArrowRight from './Icon_ArrowRight.svg';
import ArrowUp from './Icon_ArrowUp.svg';
import Car from './Icon_Car.svg';
import Close from './Icon_Close.svg';
import CO2 from './Icon_CO2.svg';
import Comment from './Icon_Comment.svg';
import Damage from './Icon_Damage.svg';
import DamageSection from './Icon_Damage_Section.svg';
import Date from './Icon_Date.svg';
import Differential from './Icon_Differential.svg';
import Documentation from './Icon_Documentation.svg';
import Download from './Icon_Download.svg';
import Engine from './Icon_Engine.svg';
import EuroClass from './Icon_EuroClass.svg';
import Exterior from './Icon_Exterior.svg';
import Features from './Icon_Features.svg';
import FuelType from './Icon_FuelType.svg';
import Fullscreen from './Icon_Fullscreen.svg';
import FullscreenExit from './Icon_FullscreenExit.svg';
import Good from './Icon_Good.svg';
import GoodAlt from './Icon_GoodAlt.svg';
import Inspect from './Icon_Inspect.svg';
import Location from './Icon_Location.svg';
import Mechanics from './Icon_Mechanics.svg';
import Menu from './Icon_Menu.svg';
import Mileage from './Icon_Mileage.svg';
import Missing from './Icon_Missing.svg';
import More from './Icon_More.svg';
import Neutral from './Icon_Neutral.svg';
import NewTab from './Icon_NewTab.svg';
import PhotoDocumentation from './Icon_PhotoDocumentation.svg';
import Placeholder from './Icon_Placeholder.svg';
import Play from './Icon_Play_Large.svg';
import Repaired from './Icon_Repaired.svg';
import Seat from './Icon_Seat.svg';
import Share from './Icon_Share.svg';
import Specification from './Icon_Specification.svg';
import IconStar from './Icon_Star.svg';
import SteeringWheel from './Icon_SterringWheel.svg';
import TestDrive from './Icon_TestDrive.svg';
import Transmission from './Icon_Transmission.svg';
import VIN from './Icon_VIN.svg';
import WheelAndTires from './Icon_WheelAndTires.svg';
import ZoomIn from './Icon_ZoomIn.svg';
import ZoomOut from './Icon_ZoomOut.svg';

export const themeIcons = {
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowUp: ArrowUp,
  car: Car,
  damageSection: DamageSection,
  close: Close,
  co2: CO2,
  comment: Comment,
  damage: Damage,
  date: Date,
  differential: Differential,
  documentation: Documentation,
  download: Download,
  engine: Engine,
  euroClass: EuroClass,
  exterior: Exterior,
  features: Features,
  fuelType: FuelType,
  fullscreen: Fullscreen,
  fullscreenExit: FullscreenExit,
  good: Good,
  goodAlt: GoodAlt,
  inspect: Inspect,
  location: Location,
  mechanics: Mechanics,
  menu: Menu,
  mileage: Mileage,
  missing: Missing,
  more: More,
  newTab: NewTab,
  photoDocumentation: PhotoDocumentation,
  placeholder: Placeholder,
  repaired: Repaired,
  seat: Seat,
  share: Share,
  specification: Specification,
  testDrive: TestDrive,
  transmission: Transmission,
  vin: VIN,
  wheelAndTires: WheelAndTires,
  zoomIn: ZoomIn,
  zoomOut: ZoomOut,
  fallback: FallbackIcon,
  neutral: Neutral,
  steeringWheel: SteeringWheel,
  iconStar: IconStar,
  play: Play,
} as const;

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  inline,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import {getSize} from 'platform/foundation';
import styled from 'styled-components';

import {
  createContext,
  Dispatch,
  HTMLProps,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import {isTrue} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

import {MENU_MAX_HEIGHT} from '../../../constants/menuMaxHeight';
import {Placement} from '../../../types/Placement';
import {useControllableState} from '../hooks/useControllableState';
import {DropdownProps} from '../types/DropdownProps';

type DropdownContainerProps = {
  children: ReactNode;
  dropdownControl?: ReactElement;
  placement?: Placement;
  isHeightLimited?: boolean;
  isOpen?: boolean;
  hasSearch?: boolean;
  onOpen?(): void;
  onClose?(): void;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  strategy?: 'fixed' | 'absolute';
  isFocusManagementDisabled?: boolean;
  isKeyboardNavigationDisabled?: boolean;
} & TestIdProps;

export const MenuContext = createContext<{
  getItemProps: (userProps?: HTMLProps<HTMLElement>) => Record<string, unknown>;
  activeIndex: number | null;
  setActiveIndex: Dispatch<SetStateAction<number | null>>;
  allowHover: boolean;
  isOpen: boolean;
  setOnItemClick: () => void;
}>({
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: () => {},
  allowHover: true,
  isOpen: false,
  setOnItemClick: () => {},
});

export function DropdownContainer(props: DropdownContainerProps) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [allowHover, setAllowHover] = useState(false);

  const elementsRef = useRef<Array<HTMLButtonElement | null>>([]);
  const labelsRef = useRef<Array<string | null>>([]);

  const tree = useFloatingTree();
  const nodeId = useFloatingNodeId();
  const parentId = useFloatingParentNodeId();
  const isNested = parentId != null;

  const item = useListItem();

  const handleOnOpenChange = (isOpen: boolean) => {
    if (isTrue(isOpen)) {
      props.onOpen?.();
    } else {
      props.onClose?.();
    }
  };

  const [isOpen, setIsOpen] = useControllableState({
    value: props.isOpen,
    onChange: handleOnOpenChange,
  });

  const _placement = props.placement ?? 'bottom-start';

  const {x, y, strategy, refs, context} = useFloating({
    nodeId,
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: isNested ? 'right-start' : _placement,
    middleware: [
      offset({
        mainAxis: isNested ? 0 : 4,
        alignmentAxis: isNested ? -4 : 0,
      }),
      shift(),
      flip(),
      inline(),
    ],
    strategy: props.strategy ?? 'fixed',
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context, {
    enabled: isNested && allowHover,
    delay: {open: 75},
    handleClose: safePolygon({blockPointerEvents: true}),
  });

  const click = useClick(context);
  const role = useRole(context, {role: 'menu'});
  const dismiss = useDismiss(context, {bubbles: true});
  const listNavigation = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    nested: isNested,
    onNavigate: setActiveIndex,
    enabled: !props.isKeyboardNavigationDisabled,
  });
  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    onMatch: isOpen ? setActiveIndex : undefined,
    activeIndex,
    enabled: !props.isKeyboardNavigationDisabled,
  });

  const {getReferenceProps, getFloatingProps, getItemProps} = useInteractions([
    hover,
    click,
    role,
    dismiss,
    listNavigation,
    typeahead,
  ]);

  // Event emitter allows you to communicate across tree components.
  // This effect closes all menus when an item gets clicked anywhere
  // in the tree.
  useEffect(() => {
    if (!tree) {
      return;
    }

    function handleTreeClick() {
      setIsOpen(false);
    }

    function onSubMenuOpen(event: {nodeId: string; parentId: string}) {
      if (event.nodeId !== nodeId && event.parentId === parentId) {
        setIsOpen(false);
      }
    }

    tree?.events.on('click', handleTreeClick);
    tree?.events.on('menuopen', onSubMenuOpen);

    return () => {
      tree?.events.off('click', handleTreeClick);
      tree?.events.off('menuopen', onSubMenuOpen);
    };
  }, [tree, nodeId, parentId]);

  useEffect(() => {
    if (isOpen && tree) {
      tree.events.emit('menuopen', {parentId, nodeId});
    }
  }, [tree, isOpen, nodeId, parentId]);

  // Determine if "hover" logic can run based on the modality of input. This
  // prevents unwanted focus synchronization as menus open and close with
  // keyboard navigation and the cursor is resting on the menu.
  useEffect(() => {
    function onPointerMove({pointerType}: PointerEvent) {
      if (pointerType !== 'touch') {
        setAllowHover(true);
      }
    }

    function onKeyDown() {
      setAllowHover(false);
    }

    window.addEventListener('pointermove', onPointerMove, {
      once: true,
      capture: true,
    });
    window.addEventListener('keydown', onKeyDown, true);
    return () => {
      window.removeEventListener('pointermove', onPointerMove, {
        capture: true,
      });
      window.removeEventListener('keydown', onKeyDown, true);
    };
  }, [allowHover]);

  return (
    <FloatingNode id={nodeId}>
      <span
        ref={useMergeRefs([refs.setReference, item.ref])}
        data-testid={suffixTestId('dropdownMenuButton', props)}
        {...getReferenceProps({onClick: props.onButtonClick})}
      >
        {props.dropdownControl}
      </span>

      <MenuContext.Provider
        value={{
          activeIndex,
          setActiveIndex,
          getItemProps,
          setOnItemClick: () => {
            setIsOpen(false);
          },
          allowHover,
          isOpen,
        }}
      >
        <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
          {isOpen && (
            <FloatingPortal>
              <FloatingFocusManager
                context={context}
                initialFocus={isNested ? -1 : 0}
                returnFocus={!isNested}
                disabled={props.isFocusManagementDisabled}
              >
                <StyledMenuList
                  ref={refs.setFloating}
                  $isHeightLimited={props.isHeightLimited}
                  $hasPaddingTop={!props.hasSearch}
                  style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                    width: 'max-content',
                  }}
                  {...getFloatingProps()}
                  data-testid={suffixTestId('dropdownMenuList', props)}
                >
                  {props.children}
                </StyledMenuList>
              </FloatingFocusManager>
            </FloatingPortal>
          )}
        </FloatingList>
      </MenuContext.Provider>
    </FloatingNode>
  );
}

type StyledMenuListProps = {
  $isHeightLimited?: DropdownProps['isHeightLimited'];
  $hasPaddingTop?: DropdownProps['hasSearch'];
};
export const StyledMenuList = styled.div<StyledMenuListProps>`
  position: relative;
  padding: ${getSize(2)};
  padding-top: ${({$hasPaddingTop}) => ($hasPaddingTop ? getSize(2) : 0)};
  overflow-y: auto;
  min-width: 216px;
  max-height: ${({$isHeightLimited}) => ($isHeightLimited ? getSize(MENU_MAX_HEIGHT) : 'none')};
  box-shadow: ${({theme}) => theme.shadows.elevation_3};
  border-radius: ${({theme}) => theme.radii.medium};
  z-index: ${({theme}) => theme.zIndices.TOAST_NOTIFICATION};
  color: inherit;
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  outline: 0;
  border-width: 0;
`;

import {match} from 'ts-pattern';

import {always} from 'ramda';

import {useDevice} from '../components/DeviceProvider/useDevice';
import {Device} from '../types/Device';
import {ValueByDevice} from '../types/ValueByDevice';
import {getValueByDevice} from '../utils/getValueByDevice';

type PrimitiveValue<T> = T extends ValueByDevice<infer P> ? P : Exclude<T, Array<unknown>>;

export function getResponsivePropValue<T>(device: Device, value: T): PrimitiveValue<T> {
  if (Array.isArray(value)) {
    const arrValue = value as Array<any>;
    return match(arrValue.length)
      .with(1, always(getValueByDevice(device, arrValue[0])))
      .with(2, always(getValueByDevice(device, arrValue[0], arrValue[1])))
      .with(3, always(getValueByDevice(device, arrValue[0], arrValue[1], arrValue[2])))
      .with(4, always(getValueByDevice(device, arrValue[0], arrValue[1], arrValue[2], arrValue[3])))
      .with(
        5,
        always(
          getValueByDevice(device, arrValue[0], arrValue[1], arrValue[2], arrValue[3], arrValue[4])
        )
      )
      .otherwise(always(value as PrimitiveValue<T>));
  } else {
    return value as PrimitiveValue<T>;
  }
}

/**
 * Platform internal tool to handle possible responsive props like spacing?: Integer | ValueByDevice<Integer>;
 */
export function useResponsivePropValue<T>(value: T): PrimitiveValue<T> {
  const device = useDevice();

  return getResponsivePropValue(device, value);
}

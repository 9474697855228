import {Clickable, Icon, useDevice} from 'platform/foundation';
import {AppLanguage} from 'platform/locale';

import {useBoolean} from 'shared';

import {AppLanguageDialogItems} from '../../../consts/languageDialogItems';
import {queryParams} from '../../../consts/queryParams';
import i18n from '../../../i18n/i18n';
import {useQueryState} from '../../../utils/useQueryState';
import {LanguageDrawer} from '../../LanguageDrawer/LanguageDrawer';
import {LanguagesDialogModal} from './LanguagesDialogModal';

type LanguageDialogComponentProps = {
  languages: AppLanguageDialogItems;
};
export const LanguageComponent = ({languages}: LanguageDialogComponentProps) => {
  const device = useDevice();
  const [, setLanguage] = useQueryState(queryParams.LNG);
  const [isLanguageDialogOpen, onLanguageDialogOpen, onLanguageDialogClose] = useBoolean(false);
  const isMobile = device === 'mobile';

  const onChangeLanguage = (lang: AppLanguage) => {
    i18n
      .changeLanguage(lang)
      .then(() => {
        setLanguage(lang);
        window.location.reload();
      })
      .catch(() => {
        throw new Error('fatal error - failed to set the application language');
      });
  };

  return (
    <>
      <Clickable onClick={onLanguageDialogOpen}>
        <Icon size={5} value={languages[i18n?.resolvedLanguage as AppLanguage].icon} />
      </Clickable>
      <LanguageDrawer
        onClose={onLanguageDialogClose}
        isVisible={isMobile && isLanguageDialogOpen}
        title={i18n.t('chooseLanguage')}
      />
      <LanguagesDialogModal
        title={i18n.t('chooseLanguage')}
        languages={languages}
        isVisible={!isMobile && isLanguageDialogOpen}
        onChoseLanguage={onChangeLanguage}
        onClose={onLanguageDialogClose}
      />
    </>
  );
};

import {createApi} from '@reduxjs/toolkit/query/react';
import {AppLanguage} from 'platform/locale';
import {z} from 'zod';

import {flatten, isNotNil, map, mergeRight, omit, pipe} from 'ramda';

import {Nullish, safeParseJSON} from 'shared';

import {getWorkspaceFromUri} from '../utils/getWorkspaceFromUri';
import {dmsBaseQuery} from './dmsBaseQuery';

export const digitalCertificateApi = createApi({
  baseQuery: dmsBaseQuery,
  reducerPath: 'digitalCertificateApi',
  tagTypes: [],
  endpoints: (build) => ({
    getPdfByAuditId: build.query<GetPdfByAuditIdApiResponse, GetPdfByAuditIdApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/vehicle-audit/${queryArg.auditId}/pdf`,
        params: {
          theme: queryArg.theme,
          lang: queryArg.locale,
          filename: queryArg.filename,
          margin: '0px 0px 0px 0px',
          contentDisposition: 'attachment',
        },
        headers: {
          'X-Workspace': queryArg.workspace,
        },
      }),
    }),
    getVehicleCatalogueByMake: build.query<
      GetVehicleCatalogueByMakeApiResponse,
      GetVehicleCatalogueByMakeApiArg
    >({
      query: (queryArg) => ({
        url: `/vehicle-catalogue/api/make-model/${queryArg.vehicleType}${
          isNotNil(queryArg.make) ? `/${queryArg.make}` : undefined
        }`,
        params: {lang: queryArg.lang},
      }),
    }),
    getAllApiEnums: build.query<GetAlphaCatalogueApiResponse, GetAlphaCatalogueApiArg>({
      query: (queryArg) => ({url: `/vehicle-catalogue/api/enum`, params: {lang: queryArg.lang}}),
    }),
    getVehicleAudit: build.query<VehicleAudit | null, GetVehicleAuditApiArg>({
      query: (queryArg) => ({
        url: `/car-audit/v1/vehicle-audit/${queryArg.auditId}`,
        headers: {
          'X-Workspace': queryArg.workspace,
        },
      }),
      transformResponse: (vehicleAudit: GetVehicleAuditApiResponse): VehicleAudit | null => {
        if (!vehicleAudit) {
          return null;
        }
        const auditStructureByAuditId = vehicleAudit.auditStructure?.find(
          (structure) => structure.id === vehicleAudit.audit.controlStructureId
        );

        if (!auditStructureByAuditId) {
          return null;
        }

        const flattenAuditStructureCategories: Category[] = [];

        const flattenCategories = (
          {childCategories, ...category}: Category,
          parentUniqueKey: string | Nullish
        ) => {
          if (isNotNil(parentUniqueKey)) {
            flattenAuditStructureCategories.push(mergeRight(category, {parentUniqueKey}));
          } else {
            flattenAuditStructureCategories.push(category);
          }
          if (childCategories?.length) {
            childCategories.forEach((c) => flattenCategories(c, parentUniqueKey));
          }
        };

        auditStructureByAuditId?.categories.forEach((c) => flattenCategories(c, c.uniqueKey));

        const flattenAuditCategories = pipe<
          [LoadAuditDataCategories[]],
          AuditCategory[][][],
          AuditCategory[]
        >(
          map((category) => [
            category.fields.map((field) => ({...field, type: 'field'})),
            category.assets.map((asset) => ({...asset, type: 'assets'})),
            category.videoAssets.map((videoAsset) => ({...videoAsset, type: 'videoAssets'})),
          ]),
          flatten
        )(vehicleAudit.audit.categories);

        return {
          assignments: vehicleAudit.assignments,
          vehicle: vehicleAudit.vehicle,
          audit: mergeRight(vehicleAudit.audit, {categories: flattenAuditCategories}),
          auditStructure: mergeRight(auditStructureByAuditId, {
            categories: flattenAuditStructureCategories,
          }),
          showEmptyFields: vehicleAudit.showEmptyFields,
        };
      },
    }),
    getTheme: build.query<ThemeConfig, GetThemeApiArg>({
      query: (queryArgs) => ({
        url: `/dms/v1/theme/${queryArgs.themeId}`,
        headers: {
          'X-Workspace': getWorkspaceFromUri().workspace,
        },
      }),
      transformResponse: (response: GetThemeResponse) => {
        const theme = safeParseJSON(response.style, z.record(z.unknown()));

        return {
          ...omit(['style'], response),
          theme,
        };
      },
    }),
  }),
});

export const {
  endpoints: {
    getVehicleAudit,
    getAllApiEnums,
    getVehicleCatalogueByMake,
    getPdfByAuditId,
    getTheme,
  },
  useLazyGetAllApiEnumsQuery,
  useLazyGetVehicleAuditQuery,
  useLazyGetVehicleCatalogueByMakeQuery,
  useLazyGetPdfByAuditIdQuery,
  useGetThemeQuery,
} = digitalCertificateApi;

type GetPdfByAuditIdApiArg = {
  auditId: string;
  workspace: string;
  locale: AppLanguage;
  filename: string;
  theme?: string;
};

type GetPdfByAuditIdApiResponse = {
  uri: string;
};

type GetVehicleCatalogueByMakeApiArg = {
  vehicleType?: string;
  lang: string;
  make?: string | null;
};

type GetVehicleCatalogueByMakeApiResponse = VehicleCatalogue;

export type VehicleCatalogue = {
  default_label: string;
  labels?: Array<{
    label: string;
    label_short?: string | Nullish;
    language: string;
  }>;
  make: string;
  vehicle_type: string;
  models?: Array<{
    default_label: string;
    labels?: Array<{
      /**
       * Label text
       */
      label: string;
      /**
       * Language code
       */
      language: string;
    }>;
    model: string;
    model_group?: {
      default_label: string;
      group: string;
      labels?: Array<{
        /**
         * Label text
         */
        label: string;
        /**
         * Language code
         */
        language: string;
      }>;
    } | null;
  }>;
}[];

export type GetAlphaCatalogueApiArg = {
  lang?: string | Nullish;
};

type GetAlphaCatalogueApiResponse = AlphaCatalogue;

export type AlphaCatalogue = {
  keys: Key[];
  property_name: string;
  vehicle_type: string;
}[];

export type Key = {
  categories: string[];
  const_key: string;
  const_key_old: string;
  labels: Label[];
  parent: string;
  properties: Property[];
};

type Label = {
  label: string;
  label_short: string;
  language: string;
};

type Property = {
  name: string;
  value: boolean;
};

export interface Assignment {
  id: string;
  identity: AssignmentIdentity;
  user: AssignmentUser;
}

export interface AssignmentIdentity {
  type: string;
  id: string;
}

export interface AssignmentUser {
  id: string;
  email?: string | Nullish;
  firstName: string;
  lastName: string;
}

export type GetVehicleAuditApiArg = {
  auditId?: string;
  workspace?: string;
};

export type GetVehicleAuditApiResponse = {
  audit: AuditApiResponse;
  auditStructure: AuditStructure[];
  vehicle: Vehicle;
  assignments: Assignment[];
  showEmptyFields: boolean;
};

export type VehicleAudit = {
  audit: Audit;
  auditStructure: AuditStructure;
  vehicle: Vehicle;
  assignments: Assignment[];
  showEmptyFields: boolean;
};

// ------------------- CarAudit API ------------------
export type Ratings = Array<{
  categoryId: string;
  paramDefinitionId: string;
  name: string;
}>;

export type AuditStructureValue = {
  content?: Content;
  value: string;
  label?: Translation | Nullish;
  relatedActions?: RelatedAction[];
  openCategoryWithKey?: string | Nullish;
  featureKey?: string | Nullish;
};

export type RelatedAction = {
  id: string;
  type: string;
  label?: Translation | Nullish;
  unit: string;
  mandatory: boolean;
  name?: Translation;
  badge?: Translation | Nullish;
  key?: string | Nullish;
  imageCategoryId?: string | Nullish;
  values?: AuditStructureValue[];
  relatedParamDefinitionIds?:
    | Array<{
        categoryId: string;
        paramDefinitionId: string;
      }>
    | Nullish;
  comparisonKey?: string | Nullish;
};

export type Content = {
  relatedActions?: RelatedAction[] | Nullish;
  additionalInformation?: RelatedAction[] | Nullish;
};

export type Category = {
  name: Translation;
  id: string;
  key?: string | Nullish;
  featureKey?: string | Nullish;
  uniqueKey: CategoryUniqueKey;
  parentUniqueKey: CategoryUniqueKey;
  icon?: string | Nullish;
  asTitle: boolean;
  paramDefinitions: Content | null;
  childCategories?: Category[];
  mandatory?: boolean;
  visible: boolean;
};

export type CategoryUniqueKey =
  | 'ACCUMULATOR'
  | 'ADDITIONAL_VEHICLE_DOCUMENTATION'
  | 'AFTER_STARTING_ALL_LIGHTS_WENT_OUT'
  | 'BACKBENCH'
  | 'BODY_CONDITION'
  | 'BRAKES'
  | 'BRAKE_DISC/BRAKE DRUM_RF'
  | 'BRAKE_DISC/BRAKE_DRUM_LF'
  | 'BRAKE_DISC_BRAKE_DRUM_LZ'
  | 'BRAKE_DISC_BRAKE_DRUM_RR'
  | 'BRAKE_FLUID'
  | 'BRAKE_SYSTEM_FUNCTIONALITY'
  | 'CARPET'
  | 'CEILINGS'
  | 'CERTIFICATE_OF_CONFORMITY'
  | 'CHASSIS'
  | 'CHASSIS_CONDITION'
  | 'CHASSIS_PLAY'
  | 'COLUMN_TRIM_ON_THE_LEFT'
  | 'COLUMN_TRIM_ON_THE_RIGHT'
  | 'CONJUCTION'
  | 'COOLING_SYSTEM'
  | 'CORROSION'
  | 'CURRENT_MILEAGE'
  | 'DAMAGE_TO_THE_INTERIOR'
  | 'DASHBOARD'
  | 'DATE_OF_FIRST_REGISTRATION'
  | 'DIAGNOSTICS'
  | 'DRIVERS_SEAT'
  | 'DRIVE_SYSTEM'
  | 'DRIVING_WHEEL'
  | 'ELECTRICAL_SYSTEM'
  | 'EMERGENCY_PARKING_BRAKE_SYSTEM_FUNCTIONALITY_E_BRAKE'
  | 'ENGINE'
  | 'EQUIPMENT'
  | 'EQUIPMENT_VALIDATION'
  | 'EXHAUST_SYSTEM'
  | 'EXHAUST_SYSTEM_CORROSION'
  | 'EXHAUST_SYSTEM_LEAK'
  | 'EXTERIOR'
  | 'EXTERIOR_DAMAGE'
  | 'FEATURE_220V_SOCKET'
  | 'FEATURE_22KW_ONBOARD_CHARGER'
  | 'FEATURE_ABS'
  | 'FEATURE_AC'
  | 'FEATURE_ACTIVE_STEERING'
  | 'FEATURE_AC_1ZONE'
  | 'FEATURE_AC_2ZONE'
  | 'FEATURE_AC_3ZONE'
  | 'FEATURE_AC_4ZONE'
  | 'FEATURE_AC_AUTOMATIC'
  | 'FEATURE_AC_MANUAL'
  | 'FEATURE_AC_MULTIZONE'
  | 'FEATURE_ADAPTIVE_LIGHTING'
  | 'FEATURE_ADDITIONAL_LIGHTS'
  | 'FEATURE_ADJUSTABLE_STEERING_WHEEL'
  | 'FEATURE_AIR_CONDITIONED_GLOVE_BOX'
  | 'FEATURE_AIR_SUSPENSION'
  | 'FEATURE_ALARM'
  | 'FEATURE_ALLOY_WHEELS'
  | 'FEATURE_AMBIENT_LIGHTING'
  | 'FEATURE_ANDROID_AUTO'
  | 'FEATURE_ANTIROLLBARS_ACTIVE'
  | 'FEATURE_APPLE_CARPLAY'
  | 'FEATURE_ARMOURED_CAR'
  | 'FEATURE_ARMREST'
  | 'FEATURE_ARMREST_REAR'
  | 'FEATURE_ATHERMIC_WINDSHIELD'
  | 'FEATURE_AUDIO_BANG_OLUFSEN'
  | 'FEATURE_AUDIO_BOSE'
  | 'FEATURE_AUDIO_BOWERS_WILKINS'
  | 'FEATURE_AUDIO_BURMESTER'
  | 'FEATURE_AUDIO_CANTON'
  | 'FEATURE_AUDIO_EXTRA'
  | 'FEATURE_AUDIO_HARMAN_KARDON'
  | 'FEATURE_AUDIO_JBL'
  | 'FEATURE_AUDIO_MARK_LEVINSON'
  | 'FEATURE_AUDIO_MERIDIAN'
  | 'FEATURE_AUTOMATIC_ACTIVATION_OF_WARNING_LIGHTS'
  | 'FEATURE_AUTOMATIC_DIFF_LOCK'
  | 'FEATURE_AUTOMATIC_LIGHTS'
  | 'FEATURE_AUTOMATIC_PARKING_BRAKE'
  | 'FEATURE_AUTONOMOUS_DRIVING'
  | 'FEATURE_AUX'
  | 'FEATURE_AUXILIARYHEATING'
  | 'FEATURE_AUXILIARYHEATING_WITHOUT_REMOTE_CONTROL'
  | 'FEATURE_AUXILIARYHEATING_WITH_REMOTE_CONTROL'
  | 'FEATURE_BIKE_CARRIER'
  | 'FEATURE_BLIND_SPOT_ASSIST'
  | 'FEATURE_BLUETOOTH'
  | 'FEATURE_BRAKE_PAD_WEAR_SENSOR'
  | 'FEATURE_CARGO_BARRIER'
  | 'FEATURE_CAR_REFRIGERATOR'
  | 'FEATURE_CATEGORY_ACCESSORIES_AND_EXTRA_FEATURES'
  | 'FEATURE_CATEGORY_COMFORT_CONVENIENCE'
  | 'FEATURE_CATEGORY_SECURITY_SAFETY_ASSISTANCE'
  | 'FEATURE_CATEGORY_TRANSMISSION_AND_SUSPENSION'
  | 'FEATURE_CD_CHANGER'
  | 'FEATURE_CD_PLAYER'
  | 'FEATURE_CEILINGMATERIAL_ALCANTARA'
  | 'FEATURE_CEILINGMATERIAL_LEATHER'
  | 'FEATURE_CEILINGMATERIAL_PREMIUM'
  | 'FEATURE_CENTRAL_LOCKING'
  | 'FEATURE_CENTRAL_LOCKING_WITH_REMOTE'
  | 'FEATURE_CONTROL_OF_GARAGE_DOORS'
  | 'FEATURE_CORNERING_LIGHTS'
  | 'FEATURE_CRUISECONTROL'
  | 'FEATURE_CRUISECONTROL_ADAPTIVE'
  | 'FEATURE_CRUISECONTROL_BASIC'
  | 'FEATURE_DAB_RADIO'
  | 'FEATURE_DAYTIME_RUNNING_LIGHTS'
  | 'FEATURE_DIFF_LOCK_FRONT'
  | 'FEATURE_DIFF_LOCK_INTER_AXLE'
  | 'FEATURE_DIFF_LOCK_REAR'
  | 'FEATURE_DIGITAL_COCKPIT'
  | 'FEATURE_DISABLED_ACCESSIBLE'
  | 'FEATURE_DISPLAY'
  | 'FEATURE_DISPLAY_FIXED'
  | 'FEATURE_DISPLAY_TILTING'
  | 'FEATURE_DVD_PLAYER'
  | 'FEATURE_DYNAUDIO'
  | 'FEATURE_EASY_OPEN_POWER_TAILGATE'
  | 'FEATURE_EDS'
  | 'FEATURE_ELECTRIC_TAILGATE'
  | 'FEATURE_ELECTRIC_WINDOWS_FRONT_ELECTRIC_WINDOWS'
  | 'FEATURE_ELECTRIC_WINDOWS_REAR_ELECTRIC_WINDOWS'
  | 'FEATURE_EMERGENCY_BRAKING_ASSIST_EBA_BAS'
  | 'FEATURE_EMERGENCY_CALL'
  | 'FEATURE_ESP'
  | 'FEATURE_EXTERIOR_LIGHT_PACKET'
  | 'FEATURE_FATIGUE_WARNING_SYSTEM'
  | 'FEATURE_FLAT_LOAD_EDGE'
  | 'FEATURE_FOIL'
  | 'FEATURE_FOLDINGROOF'
  | 'FEATURE_FOLDINGROOF_CLOTH'
  | 'FEATURE_FOLDINGROOF_SOLID'
  | 'FEATURE_FORGED_BUMPER_FRONT'
  | 'FEATURE_FORGED_BUMPER_REAR'
  | 'FEATURE_FRONT_COLLISION_WARNING_SYSTEM'
  | 'FEATURE_FRONT_FOG_LIGHTS'
  | 'FEATURE_FRONT_SEATS_AGR'
  | 'FEATURE_FRONT_SEATS_ELECTRIC_ADJUSTABLE'
  | 'FEATURE_FRONT_SEATS_HEATED'
  | 'FEATURE_FRONT_SEATS_HEIGHT_ADJUSTABLE_DRIVER'
  | 'FEATURE_FRONT_SEATS_HEIGHT_ADJUSTABLE_PASSENGER'
  | 'FEATURE_FRONT_SEATS_PASSENGER_SEAT_ISOFIX_POINT'
  | 'FEATURE_FRONT_SEATS_SPORT_SEATS'
  | 'FEATURE_FRONT_SEATS_VENTILATED'
  | 'FEATURE_FRONT_SEATS_WITH_MASSAGE_FUNCTION_DRIVER'
  | 'FEATURE_FRONT_SEATS_WITH_MASSAGE_FUNCTION_PASSENGER'
  | 'FEATURE_FRONT_SEATS_WITH_MEMORY'
  | 'FEATURE_GEAR_SHIFT_LOCK'
  | 'FEATURE_GESTURE_CONTROL'
  | 'FEATURE_GLARE_FREE_HIGH_BEAM_HEADLIGHTS'
  | 'FEATURE_GPS_TRACKING_SYSTEM'
  | 'FEATURE_HANDSFREE'
  | 'FEATURE_HANDSFREE_MOUNTED'
  | 'FEATURE_HANDSFREE_ORIGINAL'
  | 'FEATURE_HEADLAMP_WASHERS'
  | 'FEATURE_HEADLIGHTSTYPE_BI_XENON'
  | 'FEATURE_HEADLIGHTSTYPE_EXTRA'
  | 'FEATURE_HEADLIGHTSTYPE_LASER'
  | 'FEATURE_HEADLIGHTSTYPE_LED'
  | 'FEATURE_HEADLIGHTSTYPE_XENON'
  | 'FEATURE_HEAD_UP_DISPLAY'
  | 'FEATURE_HEATED_STEERING_WHEEL'
  | 'FEATURE_HEATED_WINDSHIELD'
  | 'FEATURE_HEAT_PUMP'
  | 'FEATURE_HEIGHT_ADJUSTABLE_SUSPENSION'
  | 'FEATURE_HIGH_BEAM_ASSIST'
  | 'FEATURE_HILL_DESCEND_ASSIST'
  | 'FEATURE_HILL_START_ASSIST'
  | 'FEATURE_IMMOBILIZER'
  | 'FEATURE_INDOOR_TEMPERATURE_SENSOR'
  | 'FEATURE_INDUCTION_CHARGING_FOR_SMARTPHONES'
  | 'FEATURE_INTEGRATED_MUSIC_STREAMING'
  | 'FEATURE_INTERNET_CONNECTION'
  | 'FEATURE_KEYLESS_ENTRY'
  | 'FEATURE_KEYLESS_IGNITION'
  | 'FEATURE_LEATHER_STEERING_WHEEL'
  | 'FEATURE_LIGHT_SENSOR'
  | 'FEATURE_LINE_ASSIST'
  | 'FEATURE_MONITORS_IN_HEADRESTS'
  | 'FEATURE_MP3_INTERFACE'
  | 'FEATURE_MULTIFUNCTIONAL_STEERING_WHEEL'
  | 'FEATURE_NAVIGATION_SYSTEM'
  | 'FEATURE_NIGHT_VISSION_ASSIST'
  | 'FEATURE_NUMBER_OF_AIRBAGS'
  | 'FEATURE_NUMBER_OF_AIRBAGS_1'
  | 'FEATURE_NUMBER_OF_AIRBAGS_10'
  | 'FEATURE_NUMBER_OF_AIRBAGS_12'
  | 'FEATURE_NUMBER_OF_AIRBAGS_14'
  | 'FEATURE_NUMBER_OF_AIRBAGS_2'
  | 'FEATURE_NUMBER_OF_AIRBAGS_4'
  | 'FEATURE_NUMBER_OF_AIRBAGS_6'
  | 'FEATURE_NUMBER_OF_AIRBAGS_7'
  | 'FEATURE_NUMBER_OF_AIRBAGS_8'
  | 'FEATURE_NUMBER_OF_AIRBAGS_9'
  | 'FEATURE_ON_BOARD_COMPUTER'
  | 'FEATURE_OUTDOOR_TEMPERATURE_SENSOR'
  | 'FEATURE_PADDLE_SHIFTERS'
  | 'FEATURE_PANORAMIC_ROOF'
  | 'FEATURE_PARKINGCAMERA'
  | 'FEATURE_PARKINGCAMERA_3600'
  | 'FEATURE_PARKINGCAMERA_FRONT_REAR'
  | 'FEATURE_PARKINGCAMERA_REAR_ONLY'
  | 'FEATURE_PARKINGSENSORS'
  | 'FEATURE_PARKINGSENSORS_FRONT_REAR'
  | 'FEATURE_PARKINGSENSORS_REAR_ONLY'
  | 'FEATURE_PARKING_ASSIST_SYSTEM_SELF_STEERING'
  | 'FEATURE_PASSENGER_AIRBAG_DEACTIVATION'
  | 'FEATURE_PEDESTRIAN_PROTECTION_SYSTEM'
  | 'FEATURE_POWER_ASSISTED_STEERING'
  | 'FEATURE_PREDICTIVE_EMERGENCY_BRAKING_SYSTEM_PEBS'
  | 'FEATURE_RACING_MODIFICATIONS'
  | 'FEATURE_RADIO'
  | 'FEATURE_RAIN_SENSOR'
  | 'FEATURE_REARLIGHTS_LED'
  | 'FEATURE_REARSPOILER'
  | 'FEATURE_REARSPOILER_ACTIVE'
  | 'FEATURE_REARSPOILER_FIXED'
  | 'FEATURE_REAR_CROSS_TRAFFIC_ALERT_RCTA'
  | 'FEATURE_REAR_DOOR_SUN_SHADES'
  | 'FEATURE_REAR_SEATS_DIVIDED'
  | 'FEATURE_REAR_SEATS_ELECTRIC_ADJUSTABLE_REAR_SEATS'
  | 'FEATURE_REAR_SEATS_FOLDABLE'
  | 'FEATURE_REAR_SEATS_HEATED_REAR_SEATS'
  | 'FEATURE_REAR_SEATS_ISOFIX'
  | 'FEATURE_REAR_SEATS_REMOVABLE'
  | 'FEATURE_REAR_SEATS_VENTILATION'
  | 'FEATURE_REAR_SEATS_WITH_MASSAGES'
  | 'FEATURE_REAR_WHEEL_STEERING'
  | 'FEATURE_REAR_WIPER'
  | 'FEATURE_REDUCTION_GEARBOX'
  | 'FEATURE_ROLL_BAR'
  | 'FEATURE_ROOF_CROSS_BARS'
  | 'FEATURE_ROOF_RACK'
  | 'FEATURE_ROOF_RAILS'
  | 'FEATURE_SIDE_MIRRORS_AUTO_DIMMING_SIDE_MIRRORS'
  | 'FEATURE_SIDE_MIRRORS_ELECTRICALLY_FOLDABLE'
  | 'FEATURE_SIDE_MIRRORS_ELECTRICALLY_HEATED'
  | 'FEATURE_SIDE_MIRRORS_WITHOUT_ELECTRIC_ADJUSTMENT'
  | 'FEATURE_SIDE_MIRRORS_WITH_ELECTRIC_ADJUSTMENT'
  | 'FEATURE_SIDE_STEPS'
  | 'FEATURE_SKI_BAG'
  | 'FEATURE_SLIDINGDOORS'
  | 'FEATURE_SLIDINGDOORS_BOTH_SIDES'
  | 'FEATURE_SLIDINGDOORS_LEFT'
  | 'FEATURE_SLIDINGDOORS_RIGHT'
  | 'FEATURE_SLOT_FOR_MEMORY_CARD'
  | 'FEATURE_SMART_KEY'
  | 'FEATURE_SNORKEL'
  | 'FEATURE_SOFT_CLOSE_ALL_DOORS'
  | 'FEATURE_SOFT_CLOSE_TAILGATE'
  | 'FEATURE_SPARETYRE'
  | 'FEATURE_SPARETYRE_EMERGENCY_SPARE_TYRE'
  | 'FEATURE_SPARETYRE_FULL_SPARE_TYRE'
  | 'FEATURE_SPARETYRE_TYRE_REPAIR_KIT'
  | 'FEATURE_SPORT_EXHAUST_SYSTEM'
  | 'FEATURE_SPORT_PACKAGE'
  | 'FEATURE_SPORT_SUSPENSION_SYSTEM'
  | 'FEATURE_START_STOP_SYSTEM'
  | 'FEATURE_SUNROOF'
  | 'FEATURE_SUNROOF_ELECTRIC'
  | 'FEATURE_SUNROOF_MANUAL'
  | 'FEATURE_TAILGATEOPENING'
  | 'FEATURE_TAILGATEOPENING_SIDEWAYS'
  | 'FEATURE_TAILGATEOPENING_UP'
  | 'FEATURE_TAILGATEOPENING_UP_AND_DOWN'
  | 'FEATURE_TAXI'
  | 'FEATURE_TELEPHONE'
  | 'FEATURE_THIRD_ROW_SEATS'
  | 'FEATURE_TINTED_WINDOWS'
  | 'FEATURE_TIRE_CHAINS'
  | 'FEATURE_TOUCH_SCREEN'
  | 'FEATURE_TRACTION_CONTROL_TC_ASR'
  | 'FEATURE_TRAFFIC_SIGN_RECOGNITION'
  | 'FEATURE_TRAILERCOUPLING'
  | 'FEATURE_TRAILERCOUPLING_DETACHABLE'
  | 'FEATURE_TRAILERCOUPLING_FIXED'
  | 'FEATURE_TRAILERCOUPLING_WITH_ELECTRIC_FOLDING'
  | 'FEATURE_TRAILER_STABILITY_ASSIST_TSA'
  | 'FEATURE_TUNING'
  | 'FEATURE_TV'
  | 'FEATURE_TYRE_PRESSURE_MONITORING'
  | 'FEATURE_USB'
  | 'FEATURE_VARIABLE_STIFFNESS_SUSPENSION'
  | 'FEATURE_VOICE_CONTROL'
  | 'FEATURE_WINCH'
  | 'FEATURE_WINTER_TYRES'
  | 'FEATURE_WINTER_WHEEL_SET'
  | 'FEATURE_WLAN_WIFI_HOTSPOT'
  | 'FEATURE_WOOD_TRIM'
  | 'FRONT_BUMPER'
  | 'FRONT_HOOD'
  | 'FUEL_LEAK_FROM_FUEL_SYSTEM'
  | 'FUEL_SYSTEM'
  | 'INTERIOR'
  | 'LAST_CHANGE_OF_BREAK_FLUIDS'
  | 'LAST_CHANGE_OF_DIFFERENTIAL_OIL'
  | 'LAST_CHANGE_OF_GEARBOX_OIL'
  | 'LAST_OIL_CHANGE'
  | 'LEAKAGE_OF_OPERATING_FLUIDS_FROM_OTHER_PARTS_OF_THE_DRIVE_TRAIIN'
  | 'LEAKAGE_OF_OPERATING_FLUIDS_FROM_THE_ENGINE'
  | 'LEAKAGE_OF_OPERATING_FLUIDS_FROM_THE_GEARBOX_AUXILIARY_GEARBOX'
  | 'LF_DOOR'
  | 'LF_DOOR_TRIM'
  | 'LF_FENDER'
  | 'LF_LIGHT'
  | 'LF_WHEEL'
  | 'LF_WINDOW'
  | 'LR_DOOR'
  | 'LR_DOOR_TRIM'
  | 'LR_FENDER'
  | 'LR_LIGHT'
  | 'LR_WINDOW'
  | 'L_MIRROR'
  | 'MANDATORY_EQUIPMENT_AND_BASIC_ACCESSORIES'
  | 'MECHANICS'
  | 'MORE_EQUIPMENT'
  | 'MORE_PHOTOS'
  | 'MOT_VALIDITY'
  | 'NO_ODOR_INDOORS'
  | 'NUMBER_OF_KEYS_FROM_MECHANICAL_SECURITY'
  | 'NUMBER_OF_KEYS_FROM_THE_TOWING_DEVICE'
  | 'NUMBER_OF_VEHICLE_KEYS'
  | 'OPERATING_INSTRUCTIONS'
  | 'OTHER'
  | 'OTHERS'
  | 'OTHER_PARTS_OF_THE_DRIVE_SYSTEM_DIFFRENTIALS_SEMI_AXLES_CARDAN_SHAFTS'
  | 'OVERALL_EVALUATION_OF_THE_EXTERIOR'
  | 'OVERALL_EVALUATION_OF_THE_INTERIOR'
  | 'OVERALL_EVALUATION_OF_THE_TECHNICAL_CONDITION'
  | 'PAD/BRAKE LINING_THICKNESS_IN_MM'
  | 'PAD/BRAKE LINING_WEAR_IN_PERC'
  | 'PAINT_THICKNESS'
  | 'PANORAMATIC_ROOF/WINDOW'
  | 'PASSENGER_SEAT'
  | 'PHOTODOCUMENTATION'
  | 'PLAY_IN_GEARBOX_AUXILIARY_GEARBOX'
  | 'PLAY_IN_OTHER_PARTS_OF_THE_DRIVE_TRAIN'
  | 'REAR_BUMPER'
  | 'REFRIGERANT_LEAK_FROM_COOLING_SYSTEM'
  | 'REGISTRATION_CERTIFICATE'
  | 'RF_DOOR_TRIM'
  | 'RF_FENDER'
  | 'RF_LIGHT'
  | 'RF_WHEEL'
  | 'RF_WINDOW'
  | 'ROOF'
  | 'RR_DOOR'
  | 'RR_DOOR_TRIM'
  | 'RR_FENDER'
  | 'RR_LIGHT'
  | 'RR_WHEEL'
  | 'RR_WINDOW'
  | 'R_MIRROR'
  | 'SAFETY_NUTS/WHEEL_BOLTS'
  | 'SERVICE_BOOK'
  | 'SERVICE_HISTORY'
  | 'SERVICE_INSPECTIONS'
  | 'SPARE_WHEEL'
  | 'STANDARD_HEATING'
  | 'STATE_OF_DPF_FILTERS'
  | 'STATE_OF_ENGINE'
  | 'STATE_OF_THE_ELECTRICAL_SYSTEM'
  | 'STEERING_WHEEL_STATE'
  | 'SUGGESTED_CHANGE_OF_WHEELS_AND_TIRES'
  | 'SUITCASE'
  | 'SUSPENSION'
  | 'TESTDRIVE'
  | 'THE_DIMENSION_CORRESPONDS_TO_THE_REGISTRATION_CERTIFICATE_CERTIFICATE_OF_CONFORMITY'
  | 'THE_SECOND_SET'
  | 'THE_SUITCASE_LID'
  | 'THE_VEHICLE_MODIFIED_AGAINST_PRODUCTION_TUNING'
  | 'THE_VEHICLE_SUFFERED_HAIL_DAMAGE'
  | 'TRACES_OF_ACCIDENT_REPAIR'
  | 'TRANSMISSION_AUXILIARY_TRANSMISSION'
  | 'VAT_DEDUCTION'
  | 'VEHICLE_AXEL'
  | 'VEHICLE_CRASHED_IN_THE_PAST'
  | 'VEHICLE_DOCUMENTATION'
  | 'VEHICLE_DRIVE'
  | 'VEHICLE_IDENTIFICATION_NUMBER'
  | 'VEHICLE_LIGHTNING'
  | 'VEHICLE_NON_SMOKING'
  | 'VEHICLE_UNDER_FACTORY_WARRANTY'
  | 'WHEELS_AND_TIRES'
  | 'WINDSHIELD'
  | 'YEAR_OF_PRODUCTION'
  | string;

enum SupportedLocales {
  cs = 'cs',
  sk = 'sk',
  de = 'de',
  en = 'en',
  es = 'es',
  fr = 'fr',
  it = 'it',
  pl = 'pl',
  ro = 'ro',
}

export type Translation = {[key in SupportedLocales]: string};

export type AuditStructure = {
  id: string;
  categories: Category[];
  ratings: Ratings;
  name: Translation;
  inspectionType: InspectionType;
  asTemplate?: boolean;
};

export enum InspectionType {
  VALIDATION_INSPECTION = 'validationInspection',
  VEHICLE_CONDITION = 'vehicleCondition',
  NON_VALIDATION_INSPECTION = 'nonValidationInspection',
  AUTOFILL_TEMPLATE = 'autofillTemplate',
  CHECKIN = 'checkin',
  HANDOVER = 'handover',
}

export enum InspectionState {
  FOR_CHECK = 'for_check',
  IN_PROGRESS = 'in_progress',
  FINISHED_AUDIT = 'finished_audit',
  TO_REVISION = 'to_revision',
  TO_FINISHING = 'to_finishing',
}

export type ResponseAuditDataFieldsBody = {
  paramDefinitionId: string;
  value: string | Nullish;
};

export type AuditDataAssetsFilesBody = {
  imageId: string;
  url: string;
  resizeUrl: string;
  uploadedAssetId: string | null;
  meta: string | null;
  directory: string | null;
};

type VideoVariant = {
  type: string;
  url: string;
};

export type AuditDataVideoAssetsVideBody = {
  id: string;
  videoId: string;
  originUrl: string;
  previewUrl: string;
  coverImageUrl: string;
  variants: VideoVariant[];
};

export type ResponseAuditDataVideoAssetsBody = {
  paramDefinitionId: string;
  videos: AuditDataVideoAssetsVideBody[] | never[];
};

export type ResponseAuditDataAssetsBody = {
  paramDefinitionId: string;
  files: AuditDataAssetsFilesBody[] | never[];
};

export type LoadAuditDataCategories = {
  id: string;
  fields: Array<ResponseAuditDataFieldsBody>;
  assets: Array<ResponseAuditDataAssetsBody>;
  videoAssets: Array<ResponseAuditDataVideoAssetsBody>;
};

export type AuditDataFieldsBody = {
  type: 'field';
} & ResponseAuditDataFieldsBody;

export type AuditDataAssetsBody = {
  type: 'assets';
} & ResponseAuditDataAssetsBody;

export type AuditDataVideoAssetsBody = {
  type: 'videoAssets';
} & ResponseAuditDataVideoAssetsBody;

export type AuditApiResponse = {
  id: string;
  state: InspectionState;
  user: string | null;
  finishedAt: string | null;
  controlStructureId: string;
  vehicleId: string;
  updateAt: string | null;
  categories: Array<LoadAuditDataCategories>;
  inspectionType: InspectionType | null;
  inspectionNote: string | null;
};

export type AuditCategory = AuditDataFieldsBody | AuditDataAssetsBody | AuditDataVideoAssetsBody;

export type Audit = {
  id: string;
  state: InspectionState;
  user: string | null;
  finishedAt: string | null;
  controlStructureId: string;
  vehicleId: string;
  updateAt: string | null;
  categories: AuditCategory[];
  inspectionType: InspectionType | null;
  inspectionNote: string | null;
};

// ------------------- Vehicle API ------------------

export type VehicleDataDecimal = string;

export type DimensionsSpecificationResponseBody = {
  width?: string | number | null;
  height?: string | number | null;
  length?: string | number | null;
};

export type ColorResponseBody = {
  value: string | null;
  code: string | null;
  name: string | null;
};

export type DimensionsResponseBody = {
  dimensions: DimensionsSpecificationResponseBody | null;
  operatingWeight: number | null;
  weight: number | null;
  maximalLoadCapacity: number | null;
  wheelBase: number | null;
  trunkSpace: number | null;
  height?: string | null;
  width?: string | null;
  length?: string | null;
};

export type VehicleFeatureResponseBody = {
  translation: string;
  key: string;
  isHighlighted: boolean;
  isPremium: boolean;
};

export type Vehicle = {
  id: string;
  externalId?: string | null;
  publicId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isArchived?: boolean | null;
  title: string;
  keyLocation?: string | null;
  bodyStyle?: string | null;
  carbonDioxideEmission?: VehicleDataDecimal | null;
  condition?: string | null;
  customMake?: string | null;
  customModelFamily?: string | null;
  cylinderCount?: number | null;
  dimensions: DimensionsSpecificationResponseBody | null;
  doorCountCategory?: string | null;
  drive?: string | null;
  emissionClass?: string | null;
  engineCode?: string | null;
  engineName?: string | null;
  engineNumber?: string | null;
  engineVolume?: number | null;
  exteriorColor?: ColorResponseBody | null;
  serviceBookType?: string | null;
  serviceBookState?: string | null;
  handDriveType?: string | null;
  secondaryFuelType?: string | null;
  exteriorColorFactoryName?: string | null;
  exteriorColorSpecification?: string | null;
  firstRegistrationCountry?: string | null;
  firstRegistrationOn?: string | null;
  fuelConsumptionCombined?: VehicleDataDecimal | null;
  fuelConsumptionExtraUrban?: VehicleDataDecimal | null;
  fuelConsumptionUrban?: VehicleDataDecimal | null;
  fuelType?: string | null;
  hybridType?: string | null;
  pressureVesselValidUntil?: string | null;
  gearCount?: number | null;
  hasCoc?: boolean | null;
  hasDpf?: boolean | null;
  interiorColor?: ColorResponseBody | null;
  interiorMaterial?: string | null;
  isAcceptableForTechnicalInspection?: boolean | null;
  isRegistered?: boolean | null;
  lastRegistrationCountry?: string | null;
  gasRevisionValidUntil?: string | null;
  make?: string | null;
  manufacturedOn?: string | null;
  maxTorque?: string | null;
  manufacturerColorCode?: string | null;
  manufacturerColorName?: string | null;
  maximalLoadCapacity?: number | null;
  mileage?: number | null;
  modelFamily?: string | null;
  modelFamilyGroup?: string | null;
  operatingWeight?: number | null;
  originCountry?: string | null;
  ownerCount?: number | null;
  power?: number | null;
  powerConsumptionCombined?: VehicleDataDecimal | null;
  primaryKeyCount?: number | null;
  realMileage?: number | null;
  registrationCertificateNumber?: string | null;
  otherRecords?: string | null;
  serviceContractNote?: string | null;
  vehicleRegistrationNumber?: string | null;
  registrationPlate?: string | null;
  seatCount?: number | null;
  standingPlaceCount?: number | null;
  bedCount?: number | null;
  axleCount?: number | null;
  lowDeck?: boolean | null;
  maximalAxleLoad?: number | null;
  maximalRoofLoad?: number | null;
  grossCombinationWeight?: number | null;
  dimensionsLoadingSpace?: DimensionsResponseBody | null;
  loadingCapacity?: number | null;
  technicalInspectionNote?: string | null;
  technicalInspectionValidSince?: string | null;
  technicalInspectionValidUntil?: string | null;
  transmission?: string | null;
  trim?: string | null;
  type?:
    | 'VEHICLETYPE_CARAVAN'
    | 'VEHICLETYPE_PASSENGER_CAR'
    | 'VEHICLETYPE_VAN'
    | 'VEHICLETYPE_MOTORCYCLE'
    | 'VEHICLETYPE_TRUCK'
    | 'VEHICLETYPE_SEMI_TRAILER'
    | 'VEHICLETYPE_TRAILER'
    | 'VEHICLETYPE_BUS'
    | 'VEHICLETYPE_OTHER'
    | 'VEHICLETYPE_AGRICULTURAL_MACHINE'
    | 'VEHICLETYPE_CONSTRUCTION_MACHINE'
    | string;
  variant?: string | null;
  vehicle_type?: string | null;
  vin?: string | null;
  warrantyDate?: string | null;
  lastServiceDate?: string | null;
  nextServiceDate?: string | null;
  serviceContractUntil?: string | null;
  warrantyMileage?: number | null;
  weight?: number | null;
  lastServiceMileage?: number | null;
  nextServiceMileage?: number | null;
  serviceContractMileageLimit?: number | null;
  wheelBase?: number | null;
  trunkSpace?: number | null;
  features?: VehicleFeatureResponseBody[] | null;
  chargingAC?: number | null;
  chargingTimeAC?: number | null;
  chargingDC?: number | null;
  chargingTimeDC?: number | null;
  batteryType?: string | null;
  batteryCapacityWh?: number | null;
  batteryCapacityAh?: number | null;
  batteryVoltage?: number | null;
  batteryCount?: number | null;
  chargingConnectorType?: string | null;
  electricRange?: number | null;
  batteryMileageLimit?: number | null;
  batteryWarrantyUntil?: string | null;
  batteryOwnershipType?: string | null;
  hasRangeExtender?: boolean | null;
  lengthCategory?: string | null;
  heightCategory?: string | null;
  loadingArea?: string | null;
};

interface GetThemeResponse {
  themeId: string;
  name: string;
  logo: {
    imageId: string;
    url: string;
    resizeUrl: string;
  };
  style: string; // Stringified theme object
}

interface GetThemeApiArg {
  themeId: string | null;
}

export type Theme = Record<string, unknown> | undefined;

interface ThemeConfig extends Omit<GetThemeResponse, 'style'> {
  theme: Theme;
}

import {ReactNode, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {assocPath, filter, findLastIndex, append} from 'ramda';
import {isError} from 'ramda-adjunct';

import {getRandomId} from 'shared';

import {ConfirmDialogProps} from '../../ConfirmDialog/ConfirmDialog';
import {ManagedConfirmDialogProps} from '../../ConfirmDialog/ManagedConfirmDialogProps';
import {DeleteDialogProps} from '../../DeleteDialog/DeleteDialog';
import {ManagedDeleteDialogProps} from '../../DeleteDialog/ManagedDeleteDialogProps';
import {ManagedSuccessDialogProps} from '../../SuccessDialog/ManagedSuccessDialogProps';
import {SuccessDialogProps} from '../../SuccessDialog/SuccessDialog';
import {DialogProps} from '../Dialog';
import {ManagedDialogProps} from '../ManagedDialogProps';

type DialogWithId<T> = T & {id: string};

type ManagedDialogItem =
  | {
      type: 'dialog';
      props: DialogWithId<DialogProps>;
    }
  | {
      type: 'delete';
      props: DialogWithId<DeleteDialogProps>;
    }
  | {
      type: 'confirm';
      props: DialogWithId<ConfirmDialogProps>;
    }
  | {
      type: 'success';
      props: DialogWithId<SuccessDialogProps>;
    };

export function useDialogsState() {
  /**
   * The useLocation hook is wrapped in attempt function
   * which serves as a try/catch block. It may thow an error
   * when there is no Router context. In such case, the automatic
   * closing of all dialogs on route change won't work.
   */
  const location = attempt(useLocation);

  const [dialogs, setDialogs] = useState<ManagedDialogItem[]>(NO_DIALOGS);

  useEffect(() => {
    !isError(location) && setDialogs(NO_DIALOGS);
  }, [location]);

  const addDialogItem = (
    type: ManagedDialogItem['type'],
    props: Partial<ManagedDialogItem['props']>
  ) => {
    const id = props?.id ?? getRandomId();
    const dialogItem = {
      type,
      props: {
        ...props,
        id,
        isOpen: true,
        onClose: () => closeDialog(id),
        onCloseComplete: () => {
          removeDialog(id);
          props.onCloseComplete?.();
        },
      },
    } as ManagedDialogItem;
    setDialogs((dialogs) =>
      append(
        dialogItem,
        filter((dialog) => dialog.props.id !== props.id, dialogs)
      )
    );
  };

  const openDialog = (children: ReactNode, options?: ManagedDialogProps) => {
    addDialogItem('dialog', {...options, children});
  };

  const openDeleteDialog = (options: ManagedDeleteDialogProps) => {
    addDialogItem('delete', options);
  };

  const openConfirmDialog = (options: ManagedConfirmDialogProps) => {
    addDialogItem('confirm', options);
  };

  const openSuccessDialog = (options: ManagedSuccessDialogProps) => {
    addDialogItem('success', options);
  };

  const closeDialog = (id: string) => {
    setDialogs((dialogsState) => {
      const index = dialogsState.findIndex((dialog) => dialog.props.id === id);
      return setDialogIndexToClose(index, dialogsState);
    });
  };

  const closeCurrentDialog = () => {
    setDialogs((dialogsState) => {
      const index = findLastIndex((dialog) => !!dialog.props.isOpen, dialogsState);
      return setDialogIndexToClose(index, dialogsState);
    });
  };

  const removeDialog = (id: string) =>
    void setDialogs((dialogs) => filter((dialog) => dialog.props.id !== id, dialogs));

  return {
    dialogs,
    openDialog,
    openDeleteDialog,
    openConfirmDialog,
    openSuccessDialog,
    closeCurrentDialog,
    closeDialog,
  };
}

const NO_DIALOGS: ManagedDialogItem[] = [];

const setDialogIndexToClose = (index: number, dialogs: ManagedDialogItem[]) =>
  assocPath([index, 'props', 'isOpen'], false, dialogs);

const attempt = (fn: CallableFunction) => {
  try {
    return fn();
  } catch (error: unknown) {
    return isError(error) ? error : new Error(String(error));
  }
};

import {AnimatePresence} from 'framer-motion';
import {Show} from 'platform/foundation';

import {useLayoutEffect} from 'react';
import {createPortal} from 'react-dom';

import {isNilOrEmpty} from 'ramda-adjunct';

import {LAYOUT_CONTENT_ID, Nullish, RequiredTestIdProps} from 'shared';

import {InternalLightboxControls} from '../types/InternalLightboxControls';
import {LightboxImage} from '../types/LightboxImage';
import {LightboxVideo} from '../types/LightboxVideo';
import {composeSlides} from '../utils/composeSlides';
import {Body} from './Body';

export interface LightboxProps extends RequiredTestIdProps {
  controls: InternalLightboxControls;
  images?: LightboxImage[] | Nullish;
  videos?: LightboxVideo[] | Nullish;
}

export function Lightbox(props: LightboxProps) {
  if (!props.controls) {
    throw new Error("Lightbox can't work without controls");
  }

  useLayoutEffect(() => {
    if (
      props.controls.passedLightboxId === props.controls.lightboxId &&
      document.body.style.overflow !== 'hidden'
    ) {
      document.body.style.overflow = 'hidden';
    }
    if (isNilOrEmpty(props.controls.lightboxId) && document.body.style.overflow === 'hidden') {
      document.body.style.overflow = 'auto';
    }

    const layoutContent = document.getElementById(LAYOUT_CONTENT_ID);

    if (props.controls.lightboxId && layoutContent && layoutContent?.style.overflow !== 'hidden') {
      layoutContent.style.overflow = 'hidden';
    }
    if (
      isNilOrEmpty(props.controls.lightboxId) &&
      layoutContent &&
      layoutContent?.style.overflow === 'hidden'
    ) {
      layoutContent.style.overflow = 'auto';
    }
  }, [props.controls.lightboxId, props.controls.passedLightboxId]);

  const slides = composeSlides({images: props.images, videos: props.videos});

  if (isNilOrEmpty(slides) || isNilOrEmpty(props.controls.lightboxId)) {
    return null;
  }

  return (
    <Show when={props.controls.passedLightboxId === props.controls.lightboxId}>
      {createPortal(
        <AnimatePresence>
          <Body data-testid={props['data-testid']} controls={props.controls} slides={slides!} />
        </AnimatePresence>,
        document.body
      )}
    </Show>
  );
}

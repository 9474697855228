import {Component, ErrorInfo, ReactNode} from 'react';

import {ThemeIconKey} from '../../utils/foundationTheme';

interface IconErrorBoundaryProps {
  children: ReactNode;
  fallback: ReactNode;
  icon: ThemeIconKey;
}

export class IconErrorBoundary extends Component<IconErrorBoundaryProps, {hasError: boolean}> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Icon loading failed:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <span title={`${this.props.icon} icon`}>{this.props.fallback}</span>;
    }

    return this.props.children;
  }
}

import {AppLanguage} from 'platform/locale';

import {always, isNotNil, tryCatch} from 'ramda';
import {isArray, isNilOrEmpty, isObject} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {AuditStructureValue} from '../api/digitalCertificateApi';
import {Damage, DamageValueTypes} from '../types/Damage';
import {DataUtilParams} from '../types/DataUtilParams';
import {getAuditAssetsById} from './getAuditAssetsById';
import {getAuditFieldById} from './getAuditFieldById';
import {getDamageFilesByDamageId} from './getDamageFilesByDamageId';
import {getRelatedActionsByUniqueKeys} from './getRelatedActionsByUniqueKeys';
import {mapAssetsToSlideImages} from './mapAssetsToSlideImages';

type ParsedValue = {
  damageId: string;
  type?: string;
};

interface DamageValuesParams extends DataUtilParams {
  uniqueKey: string;
  parentUniqueKey: string;
  point: string;
}

export function getDamageValues(params: DamageValuesParams) {
  if (!params.vehicleAudit || !params.vehicleAudit.audit || !params.vehicleAudit.auditStructure) {
    return null;
  }

  const relatedActions = getRelatedActionsByUniqueKeys({
    parentUniqueKey: params.parentUniqueKey,
    uniqueKey: params.uniqueKey,
    categories: params.vehicleAudit.auditStructure.categories,
  });

  if (!relatedActions?.length) {
    return null;
  }

  const relatedActionsResult = relatedActions.reduce(
    (damageValues: Record<DamageValueTypes, Damage[]>, action) => {
      const auditField = getAuditFieldById(action.id, params.vehicleAudit!.audit.categories);

      const parsedValues: ParsedValue[] | ParsedValue | null = tryCatch(
        JSON.parse,
        always(null)
      )(auditField?.value ?? '');

      if (isNotNil(parsedValues) && (isArray(parsedValues) || isObject(parsedValues))) {
        const valuesToMap: ParsedValue[] = isArray(parsedValues) ? parsedValues : [parsedValues];

        valuesToMap.forEach((item: ParsedValue) => {
          if (action.type === 'photo') {
            const files = getAuditAssetsById(
              action.id,
              params.vehicleAudit!.audit.categories
            )?.files;

            if (!files?.length) {
              return damageValues;
            }
            const convertedFiles = mapAssetsToSlideImages(files);

            const damageFilesByDamageId = getDamageFilesByDamageId(convertedFiles);

            damageFilesByDamageId.forEach((damageId) => {
              const filteredDamages = convertedFiles.filter((file) => {
                const parsedMeta = JSON.parse(file.meta ?? '') as {damageId: string};
                return parsedMeta.damageId === damageId;
              });
              damageValues.photos.push({
                type: 'photos',
                damageId,
                slides: filteredDamages,
                isLocation: action.label?.en === 'Location',
                title: action.name?.[params.language],
              });
            });
          }

          if (action.type === 'selectableButton') {
            const value = getLabelByKey(params.language, action.values, item.type);
            if (isNilOrEmpty(value)) {
              return damageValues;
            }
            damageValues.values.push({
              type: 'values',
              damageId: item.damageId,
              key: item.type,
              title: action.name?.[params.language],
              value,
            });
          }

          if (action.type === 'enhancedComment') {
            damageValues.comments.push({
              type: 'comments',
              damageId: item.damageId,
              key: item.type,
              value: item?.type,
              title: action.name?.[params.language],
            });
          }
        });
      }

      return damageValues;
    },
    {values: [], photos: [], comments: []}
  );
  return {...relatedActionsResult, point: params.point};
}

const getLabelByKey = (
  language: AppLanguage,
  values: AuditStructureValue[] | Nullish,
  key: string | Nullish
) => {
  const auditStructureValue = values?.find((item) => item.value === key);
  if (!auditStructureValue?.label) {
    return null;
  }
  return auditStructureValue.label[language];
};

import {AppLanguage} from 'platform/locale';

import {always, isNil, map, pipe, reject, tryCatch} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {getLanguageOfInstructions} from '../(sections)/Documentation/utils/getLanguageOfInstructions';
import {getServiceBookState} from '../(sections)/Documentation/utils/getServiceBookState';
import {getTechnicalCertificate} from '../(sections)/Documentation/utils/getTechnicalCertificate';
import {getVehicleUnderFactoryWarranty} from '../(sections)/Documentation/utils/getVehicleUnderFactoryWarranty';
import {AuditCategory, RelatedAction, VehicleAudit} from '../api/digitalCertificateApi';
import {getAdditionalRelatedActionsByUniqueKeys} from './getAdditionalRelatedActionsByUniqueKeys';
import {getAuditFieldLabel} from './getAuditFieldLabel';
import {getAuditFieldValue} from './getAuditFieldValue';
import {getAuditFieldValueWithUnit} from './getAuditFieldValueWithUnit';
import {getRelatedActionByComparisonKey} from './getRelatedActionByComparisonKey';
import {getRelatedActionsByUniqueKeys} from './getRelatedActionsByUniqueKeys';

interface DocumentationParams {
  language: AppLanguage;
  vehicleAudit: VehicleAudit | Nullish;
}

export function getDocumentation(params: DocumentationParams) {
  if (!params.vehicleAudit || !params.vehicleAudit.audit || !params.vehicleAudit.auditStructure) {
    return null;
  }

  const relatedActions = getRelatedActionsByUniqueKeys({
    parentUniqueKey: 'VEHICLE_DOCUMENTATION',
    categories: params.vehicleAudit.auditStructure.categories,
  });

  const additionalActions = getAdditionalRelatedActionsByUniqueKeys({
    parentUniqueKey: 'VEHICLE_DOCUMENTATION',
    categories: params.vehicleAudit.auditStructure.categories,
  });

  if (!relatedActions?.length || !additionalActions?.length) {
    return null;
  }

  return {
    vin: getVin({
      relatedActions,
      additionalActions,
      auditCategories: params.vehicleAudit.audit.categories,
    }),
    status: getAuditFieldValue({
      key: 'documentationStatus',
      relatedActions,
      auditCategories: params.vehicleAudit.audit.categories,
    }),
    dateOfManufacture: getDateOfManufacture({
      relatedActions,
      additionalActions,
      auditCategories: params.vehicleAudit.audit.categories,
    }),
    firstRegistration: getFirstRegistration({
      relatedActions,
      additionalActions,
      auditCategories: params.vehicleAudit.audit.categories,
    }),
    serviceBookType: getAuditFieldLabel({
      key: 'documentationServiceBookType',
      relatedActions,
      auditCategories: params.vehicleAudit.audit.categories,
      language: params.language,
    }),
    serviceBookState: getServiceBookState({
      relatedActions,
      auditCategories: params.vehicleAudit.audit.categories,
      language: params.language,
    }),
    currentMileage: getCurrentMileage({
      relatedActions,
      additionalActions,
      auditCategories: params.vehicleAudit.audit.categories,
    }),
    cocListStatus: getAuditFieldLabel({
      key: 'documentationCocListStatus',
      relatedActions,
      auditCategories: params.vehicleAudit.audit.categories,
      language: params.language,
    }),
    technicalCert: getTechnicalCertificate({
      key: 'documentationTechnicalCertStatus',
      relatedActions,
      auditCategories: params.vehicleAudit.audit.categories,
      language: params.language,
    }),
    vehicleUnderFactoryWarranty: getVehicleUnderFactoryWarranty({
      key: 'documentationVehicleUnderFactoryWarrantyStatus',
      relatedActions,
      auditCategories: params.vehicleAudit.audit.categories,
      language: params.language,
    }),
    operatingInstructions: getAuditFieldLabel({
      key: 'documentationOperatingInstructions',
      relatedActions,
      auditCategories: params.vehicleAudit.audit.categories,
      language: params.language,
    }),
    languageOfInstructions: getLanguageOfInstructions({
      key: 'documentationOperatingInstructions',
      relatedActions,
      auditCategories: params.vehicleAudit.audit.categories,
      language: params.language,
    }),
    mandatoryEquipmentAndAccessories: getMandatoryEquipmentAndAccessories({
      relatedActions,
      additionalActions,
      auditCategories: params.vehicleAudit.audit.categories,
      language: params.language,
    }),
  };
}

type DocumentationUtilParams = {
  relatedActions: RelatedAction[];
  additionalActions: RelatedAction[];
  auditCategories: AuditCategory[];
};
const getVin = (params: DocumentationUtilParams) => {
  if (isNil(params.relatedActions)) {
    return null;
  }

  return {
    value: getAuditFieldValue({
      key: 'documentationVin',
      relatedActions: params.relatedActions,
      auditCategories: params.auditCategories,
    }),
    comment: getAuditFieldValue({
      key: 'documentationVinComment',
      relatedActions: params.additionalActions,
      auditCategories: params.auditCategories,
    }),
  };
};
const getDateOfManufacture = (params: DocumentationUtilParams) => {
  if (isNil(params.relatedActions)) {
    return null;
  }

  return {
    value: getAuditFieldValue({
      key: 'documentationDateOfManufacture',
      relatedActions: params.relatedActions,
      auditCategories: params.auditCategories,
    }),
    comment: getAuditFieldValue({
      key: 'documentationDateOfManufactureComment',
      relatedActions: params.additionalActions,
      auditCategories: params.auditCategories,
    }),
  };
};
const getFirstRegistration = (params: DocumentationUtilParams) => {
  if (isNil(params.relatedActions)) {
    return null;
  }

  return {
    value: getAuditFieldValue({
      key: 'documentationFirstRegistration',
      relatedActions: params.relatedActions,
      auditCategories: params.auditCategories,
    }),
    comment: getAuditFieldValue({
      key: 'documentationFirstRegistrationComment',
      relatedActions: params.additionalActions,
      auditCategories: params.auditCategories,
    }),
  };
};
const getCurrentMileage = (params: DocumentationUtilParams) => {
  if (isNil(params.relatedActions)) {
    return null;
  }

  return {
    value: getAuditFieldValueWithUnit({
      key: 'documentationCurrentMileage',
      relatedActions: params.relatedActions,
      auditCategories: params.auditCategories,
    }),
    comment: getAuditFieldValue({
      key: 'documentationCurrentMileageComment',
      relatedActions: params.additionalActions,
      auditCategories: params.auditCategories,
    }),
  };
};
const getMandatoryEquipmentAndAccessories = (
  params: DocumentationUtilParams & {language: AppLanguage}
) => {
  const relatedAction = getRelatedActionByComparisonKey(
    'documentationMandatoryEquipmentAndAccessories',
    params.relatedActions
  );
  const auditValueKeys = getAuditFieldValue({
    key: 'documentationMandatoryEquipmentAndAccessories',
    relatedActions: params?.relatedActions,
    auditCategories: params.auditCategories,
  });

  if (!relatedAction?.values?.length || isNilOrEmpty(auditValueKeys)) {
    return null;
  }

  const getFieldLabel = (keyValue: string) => {
    const auditStructureValue = relatedAction.values?.find((v) => v.value === keyValue);
    return auditStructureValue?.label?.[params.language] ?? null;
  };

  return {
    value: pipe(
      tryCatch(JSON.parse, always([])),
      map(getFieldLabel),
      reject(isNilOrEmpty),
      (items) => (items?.length ? items.join(', ') : null)
    )(auditValueKeys!),
    comment: getAuditFieldValue({
      key: 'documentationMandatoryEquipmentAndAccessoriesComment',
      relatedActions: params.additionalActions,
      auditCategories: params.auditCategories,
    }),
  };
};

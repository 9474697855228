import i18n from 'i18next';
import {Portal, useScrollLock} from 'platform/components';
import {
  Box,
  Center,
  Clickable,
  getSize,
  getValueByDevice,
  Grid,
  HStack,
  Icon,
  Text,
  useDevice,
  VStack,
} from 'platform/foundation';
import {AppLanguage} from 'platform/locale';
import styled from 'styled-components';

import {useRef} from 'react';

import {useOutsideClick} from 'shared';

import {AppLanguageDialogItems} from '../../../consts/languageDialogItems';
import {ButtonIcon} from '../../ButtonIcon/ButtonIcon';

type LanguagesDialogModalProps = {
  languages: AppLanguageDialogItems;
  isVisible: boolean;
  onChoseLanguage: (lang: AppLanguage) => void;
  onClose: () => void;
  title: string;
};
export const LanguagesDialogModal = ({
  languages,
  isVisible,
  onChoseLanguage,
  title,
  onClose,
}: LanguagesDialogModalProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const device = useDevice();
  const modalWidth = getValueByDevice(device, 120, 700, 800, 800);

  useOutsideClick({
    ref,
    enabled: isVisible,
    handler: () => {
      onClose();
    },
  });

  useScrollLock({autoLock: isVisible});

  if (!isVisible) {
    return null;
  }

  return (
    <Portal>
      <Box top={0} left={0} position="fixed" zIndex="LANGUAGE_DIALOG">
        <Box width="100vw" height="100vh" position="absolute" backgroundColor="fill.overlayMedium">
          <Center width="100%" height="100%">
            <ModalContainer ref={ref} width={modalWidth} isVisible={isVisible}>
              <VStack spacing={8}>
                <HStack align="center" justify="space-between">
                  <Text alternative size="large">
                    {title}
                  </Text>
                  <ButtonIcon variant="outline" icon="close" onClick={onClose} />
                </HStack>
                <Grid columns={2}>
                  {Object.entries(languages).map(([lang, item]) => {
                    const handleClickButton = () => {
                      onChoseLanguage(lang as AppLanguage);
                    };
                    return (
                      <LanguageItem key={item.title} isActive={i18n?.resolvedLanguage === lang}>
                        <Clickable onClick={handleClickButton}>
                          <HStack align="center" spacing={2}>
                            <Icon value={item.icon} size={5} />
                            <Text alternative>{item.title}</Text>
                          </HStack>
                        </Clickable>
                      </LanguageItem>
                    );
                  })}
                </Grid>
              </VStack>
            </ModalContainer>
          </Center>
        </Box>
      </Box>
    </Portal>
  );
};

const LanguageItem = styled.div<{isActive: boolean}>`
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 16px;
  background-color: ${({theme, isActive}) => (isActive ? theme.colors.fill.greyLight : 'initial')};

  &:hover {
    border-color: ${({theme}) => theme.colors.severity.neutralLight};
  }
`;

const ModalContainer = styled.div<{width: number; isVisible: boolean}>`
  width: ${({width}) => width}px;
  height: auto;
  border-radius: 25px;
  box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: ${getSize(10)};
  display: ${({isVisible}) => (isVisible ? 'block' : 'none')};
`;

import {Box, HStack, Image, makeImageUrl, Show, VStack} from 'platform/foundation';

import {Helmet} from 'react-helmet-async';

import {flatten, isNil, reject} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {EMPTY_PLACEHOLDER} from 'shared';

import {Logo} from '../../components/Navigation/components/Logo';
import {useGetDigitalCertificateData} from '../../hooks/useGetDigitalCertificateData';
import {getCertificateHeader} from '../../utils/getCertificateHeader';
import {getPhotosByKeys} from '../../utils/getPhotosByKeys';
import {CertificateBadgePrint} from './components/CertificateBadge.print';
import {getExteriorPhotos} from './utils/getExteriorPhotos';
import {getInteriorPhotos} from './utils/getInteriorPhotos';

export function HeroPrint() {
  const {vehicleAudit, vehicleCatalogue} = useGetDigitalCertificateData();

  const exteriorPhotos = getExteriorPhotos(vehicleAudit);
  const interiorPhotos = getInteriorPhotos(vehicleAudit);
  const morePhotos = getPhotosByKeys({
    vehicleAudit,
    uniqueKey: 'MORE_PHOTOS',
    parentUniqueKey: 'PHOTODOCUMENTATION',
  });
  const allPhotos = flatten(reject(isNil, [exteriorPhotos, interiorPhotos, morePhotos]));

  const certificateHeader = getCertificateHeader({
    vehicle: vehicleAudit?.vehicle,
    vehicleCatalogue,
  });

  return (
    <Box width="100%" overflow="hidden" paddingHorizontal={10} paddingVertical={5}>
      <VStack spacing={10} width="100%" height="100%">
        <Helmet>
          <title>{certificateHeader?.makeModel}</title>
        </Helmet>
        <HStack>
          <Logo />
        </HStack>
        <CertificateBadgePrint
          makeModel={certificateHeader?.makeModel ?? EMPTY_PLACEHOLDER}
          specification={certificateHeader?.specification}
        />

        <Show when={isNotNilOrEmpty(allPhotos)}>
          <Image
            fit="cover"
            position="center"
            ratio="3 / 2"
            width="100%"
            height={150}
            borderRadius="small"
            src={makeImageUrl(allPhotos![0]?.resizeUrl, 200)}
            alt={allPhotos![0]?.title}
          />
        </Show>
      </VStack>
    </Box>
  );
}

import {LocaleContextValue, useDateTimeFormatter} from 'platform/locale';

import {isNotNil} from 'ramda';

import {EMPTY_PLACEHOLDER, Nullish, parseDate} from 'shared';

import {VehicleAudit} from '../api/digitalCertificateApi';
import {getSlideshowByUniqueKey} from '../components/Slideshow/utils/getSlideshowByUniqueKey';
import {SectionConfig} from '../types/SectionConfig';
import {SlideImage} from '../types/SlideImage';
import {getDocumentation} from '../utils/getDocumentation';
import {DocumentationParams, getDocumentationItemParams} from '../utils/getDocumentationItemParams';
import {getIfShouldBeDocumentationHide} from '../utils/getIfShouldBeDocumentationHide';

export type DocumentationDataBundle = {
  documentation: ReturnType<typeof getDocumentation>;
  documentationSlideshow:
    | {title: string; comment: string | null; slides: SlideImage[] | null}[]
    | null;
  documentationItemParams: DocumentationParams | null;
  firstRegistration: string;
  dateOfManufacture: string;
  sectionConfig: SectionConfig;
};

export const useGetDataForDocumentation = (
  vehicleAudit: VehicleAudit | null | undefined,
  locale: LocaleContextValue
): DocumentationDataBundle => {
  const formatDateTime = useDateTimeFormatter();
  const getDate = (value: string | number | Nullish) =>
    isNotNil(value) ? formatDateTime('dateShort', parseDate(value)) : null;

  const documentation = getDocumentation({vehicleAudit, language: locale.language});
  const documentationSlideshow = getSlideshowByUniqueKey({
    vehicleAudit,
    language: locale.language,
    parentUniqueKey: 'VEHICLE_DOCUMENTATION',
  });
  const documentationItemParams = getDocumentationItemParams(documentation, locale, getDate);
  const dateOfManufacture = getDate(documentation?.dateOfManufacture?.value) ?? EMPTY_PLACEHOLDER;
  const firstRegistration = getDate(documentation?.firstRegistration?.value) ?? EMPTY_PLACEHOLDER;
  const shouldBeHidden = getIfShouldBeDocumentationHide(documentation, documentationItemParams);

  return {
    documentation,
    documentationSlideshow,
    documentationItemParams,
    firstRegistration,
    dateOfManufacture,
    sectionConfig: {isHidden: shouldBeHidden, name: 'VEHICLE_DOCUMENTATION'},
  };
};

import {motion, usePresence} from 'framer-motion';
import {css, useTheme} from 'styled-components';
import {match} from 'ts-pattern';

import {forwardRef, ReactNode, Ref, useEffect} from 'react';

import {always} from 'ramda';

import {DialogSize} from '../types/DialogSize';
import {dialogTransitions} from '../utils/dialogTransitions';

export interface DialogTransitionProps {
  size?: DialogSize;
  preset?: 'slideInBottom' | 'slideInRight' | 'scale' | 'none';
  children: ReactNode;
  isEnlarged?: boolean;
}

export const DialogTransition = forwardRef((props: DialogTransitionProps, ref: Ref<any>) => {
  const theme = useTheme();
  const motionProps = dialogTransitions[props.preset ?? 'none'];

  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    if (!isPresent && safeToRemove) {
      setTimeout(safeToRemove);
    }
  }, [isPresent, safeToRemove]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <motion.section
      ref={ref}
      onClick={handleClick}
      {...motionProps}
      css={css`
        display: flex;
        flex-direction: column;
        position: relative;
        outline: 0;
        width: 100%;
        background-color: ${theme.colors.palettes.white[10][100]};
        box-shadow: ${theme.shadows.elevation_4};
        border-width: 1px;
        border-radius: ${theme.radii.medium};
        border-color: ${theme.colors.palettes.neutral[40][100]};
        margin: 0 ${theme.getSize(15)};
        max-height: ${`calc(100% -  ${theme.getSize(props.isEnlarged ? 8 : 30)})`};
        max-width: ${match<DialogSize | undefined>(props.size)
          .with('small', always('400px'))
          .with('large', always('1200px'))
          .with('xLarge', always('2000px'))
          .otherwise(always('800px'))};
      `}
    >
      {props.children}
    </motion.section>
  );
});

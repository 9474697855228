import {DotLottieReact} from '@lottiefiles/dotlottie-react';
import {useTheme} from 'styled-components';

import {Suspense, use} from 'react';

import {Integer} from '../../types/Integer';
import {ThemeLottieKey} from '../../utils/foundationTheme';
import {Box} from '../Box/Box';

export interface LottieProps {
  value: ThemeLottieKey;
  minHeight: Integer;
  autoplay?: boolean;
  loop?: boolean;
}

const Renderer = (
  props: {
    loader: Promise<string>;
  } & Omit<LottieProps, 'value' | 'minHeight'>
) => {
  const source = use<string>(props.loader);
  return <DotLottieReact src={source} {...props} />;
};

export function Lottie(props: LottieProps) {
  const {value, minHeight, ...rest} = props;
  const theme = useTheme();

  const loader = theme.lotties?.[value]();
  if (!loader) {
    return null;
  }

  return (
    <Suspense fallback={<Box minHeight={minHeight} />}>
      <Renderer loader={loader} {...rest} />
    </Suspense>
  );
}

import {Text, Heading, Show, VStack, Box, Space, Lottie, ThemeLottieKey} from 'platform/foundation';

import {Suspense} from 'react';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {Dialog, DialogProps} from '../Dialog/Dialog';
import {TimedProgressButton} from '../TimedProgressButton/TimedProgressButton';

export interface SuccessDialogProps
  extends RequiredTestIdProps,
    Omit<DialogProps, 'data-testid' | 'children'> {
  heading: string;
  buttonLabel: string;
  subheading?: string;
  closeDelay?: number;
  animationKey?: ThemeLottieKey;
}

export function SuccessDialog(props: SuccessDialogProps) {
  const delay = props.closeDelay ?? 5000;

  return (
    <Dialog
      size="small"
      id={props.id}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onCloseComplete={props.onCloseComplete}
    >
      <Suspense>
        <Box padding={8} width="100%" height="100%">
          <VStack justify="center" align="center" spacing={2}>
            <Lottie value={props.animationKey ?? 'action/done'} autoplay minHeight={38} />
            <Heading size={2} align="center">
              {props.heading}
            </Heading>
            <Show when={isNotNilOrEmpty(props.subheading)}>
              <Text align="center" color="tertiary">
                {props.subheading}
              </Text>
            </Show>
            <Space vertical={5} />
            <TimedProgressButton
              label={props.buttonLabel}
              data-testid={suffixTestId('successDialog', props)}
              variant="success"
              onClick={props.onClose}
              onProgressEnd={props.onClose}
              durationInMs={delay}
            />
          </VStack>
        </Box>
      </Suspense>
    </Dialog>
  );
}

import {isFeatureEnabled} from 'feature-flags';

import {call, complement, pipe, when} from 'ramda';
import {isFunction} from 'ramda-adjunct';

type Element<T> = T;
type ElementGetter<T> = () => T;
type ElementOrElementGetter<T> = Element<T> | ElementGetter<T>;

// More flexible method signatures that accept multiple elements
type WhenFn<T> = (when: unknown, ...elements: ElementOrElementGetter<T>[]) => ProxiedArray<T>;
type WhenNotFn<T> = (whenNot: unknown, ...elements: ElementOrElementGetter<T>[]) => ProxiedArray<T>;
type WhenFeatureEnabledFn<T> = (
  whenFeatureEnabled: string,
  ...elements: ElementOrElementGetter<T>[]
) => ProxiedArray<T>;
type WhenFeatureNotEnabledFn<T> = (
  whenFeatureNotEnabled: string,
  ...elements: ElementOrElementGetter<T>[]
) => ProxiedArray<T>;

type AddFn<T> = (...elements: ElementOrElementGetter<T>[]) => ProxiedArray<T>;

type ProxiedArray<T> = {
  when: WhenFn<T>;
  whenNot: WhenNotFn<T>;
  whenFeatureEnabled: WhenFeatureEnabledFn<T>;
  whenFeatureNotEnabled: WhenFeatureNotEnabledFn<T>;
  add: AddFn<T>;
} & T[];

/**
 * @about Use to build arrays with conditionally added elements
 * Get rid of `...(isTrue ? ['Foo'] : [])` and replace by `.when(isTrue, 'Foo')`
 *
 * @example const fruit = buildArray().add('Orange').when(weatherIsNice, 'Lime', 'Lemon').whenNot(isRaining, 'Grapes')
 * @example const fruit = buildArray(['Default fruit']).add('Orange', 'Apple')
 * @example const fruit = buildArray<string | number>().add('Orange').add(123)
 */
export function buildArray<T = unknown>(initial?: T[]): ProxiedArray<T> {
  const initialArray = (initial ? [...initial] : []) as ProxiedArray<T>;

  const proxiedArray = new Proxy(initialArray, {
    get: (target, prop, receiver) => {
      if ('add' === prop) {
        return (...elements: ElementOrElementGetter<T>[]) => {
          elements.forEach((element) => {
            target.push(getElement(element));
          });
          return proxiedArray;
        };
      }

      if ('when' === prop) {
        return (condition: unknown, ...elements: ElementOrElementGetter<T>[]) => {
          const isConditionMet = getConditionResult(condition);
          if (isConditionMet && elements.length > 0) {
            elements.forEach((element) => {
              target.push(getElement(element));
            });
          }
          return proxiedArray;
        };
      }

      if ('whenNot' === prop) {
        return (condition: unknown, ...elements: ElementOrElementGetter<T>[]) => {
          const isConditionNotMet = complement(getConditionResult)(condition);
          if (isConditionNotMet && elements.length > 0) {
            elements.forEach((element) => {
              target.push(getElement(element));
            });
          }
          return proxiedArray;
        };
      }

      if ('whenFeatureEnabled' === prop) {
        return (featureFlag: string, ...elements: ElementOrElementGetter<T>[]) => {
          if (isFeatureEnabled(featureFlag) && elements.length > 0) {
            elements.forEach((element) => {
              target.push(getElement(element));
            });
          }
          return proxiedArray;
        };
      }

      if ('whenFeatureNotEnabled' === prop) {
        return (featureFlag: string, ...elements: ElementOrElementGetter<T>[]) => {
          if (!isFeatureEnabled(featureFlag) && elements.length > 0) {
            elements.forEach((element) => {
              target.push(getElement(element));
            });
          }
          return proxiedArray;
        };
      }

      return Reflect.get(target, prop, receiver);
    },
  });

  return proxiedArray;
}

const getElement: <T>(element: ElementOrElementGetter<T>) => Element<T> = when(isFunction, call);
const getConditionResult: <T>(element: unknown) => boolean = pipe(when(isFunction, call), Boolean);

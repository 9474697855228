import {getAllFeatureFlags} from './getAllFeatureFlags';

export const isFeatureEnabled = (featureName: string) => {
  const flags = getAllFeatureFlags();

  if (!flags) {
    return false;
  }

  return flags?.[featureName]?.enabled ?? false;
};

// eslint-disable-next-line eag/no-external-date-time-format-functions -- Intended to use here
import {format} from 'date-fns';

import {curry} from 'ramda';

import {AppLanguage} from '../../../types/AppLanguage';
import {LocaleConfig} from '../../../types/LocaleConfig';
import {LocaleDateTimeFormat} from '../../../types/LocaleDateTimeFormat';
import {convertStringLocaleToDateFns} from '../../../utils/convertStringLocaleToDateFns';

function uncurriedFormatDateTime(
  localeConfig: LocaleConfig,
  language: AppLanguage,
  dateTimeFormat: LocaleDateTimeFormat,
  value: Date
) {
  const formatString = localeConfig.dateTimeFormat[dateTimeFormat];
  return format(value, formatString, {
    locale: convertStringLocaleToDateFns(language),
  });
}

export const formatDateTime = curry(uncurriedFormatDateTime);

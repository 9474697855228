export const DIALOGS_EVENTS = {
  open: 'eag-open-dialog',
  close: 'eag-close-dialog',
  closeCurrent: 'eag-close-current-dialog',
  openDelete: 'eag-open-delete-dialog',
  openConfirm: 'eag-open-confirm-dialog',
  openSuccess: 'eag-open-success-dialog',
} as const;

export type DialogEventType = keyof typeof DIALOGS_EVENTS;

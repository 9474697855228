import {Box, Heading, Show, Stack, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useLocale} from 'platform/locale';

import {isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {buildArray, EMPTY_PLACEHOLDER, Nullish, parseDate} from 'shared';

import {CarouselPrint} from '../../components/Carousel/Carousel.print';
import {Comment} from '../../components/Comment/CommentExpandable';
import {
  DocumentationAttribute,
  DocumentationItem,
} from '../../components/DocumentationItem/DocumentationItem';
import {SectionPrint} from '../../components/SectionPrint/SectionPrint';
import {Separator} from '../../components/Separator/Separator';
import {getSlideshowByUniqueKey} from '../../components/Slideshow/utils/getSlideshowByUniqueKey';
import {useGetDigitalCertificateData} from '../../hooks/useGetDigitalCertificateData';
import i18n from '../../i18n/i18n';
import {getDocumentation} from '../../utils/getDocumentation';
import {getValueWithUnit} from '../../utils/getValueWithUnit';

export function DocumentationPrint() {
  const {vehicleAudit} = useGetDigitalCertificateData();

  const locale = useLocale();
  const formatDateTime = useDateTimeFormatter();

  const documentation = getDocumentation({vehicleAudit, language: locale.language});
  const documentationSlideshow = getSlideshowByUniqueKey({
    vehicleAudit,
    language: locale.language,
    parentUniqueKey: 'VEHICLE_DOCUMENTATION',
  });

  const getDate = (value: string | number | Nullish) =>
    isNotNil(value) ? formatDateTime('dateShort', parseDate(value)) : null;

  if (!documentation) {
    return null;
  }

  const firstRegistration = getDate(documentation.firstRegistration?.value) ?? EMPTY_PLACEHOLDER;
  const dateOfManufacture = getDate(documentation.dateOfManufacture?.value) ?? EMPTY_PLACEHOLDER;
  const currentMileage = getValueWithUnit({
    auditValue: documentation.currentMileage?.value,
    localeConfig: locale.localeConfig,
    decimals: 0,
    defaultUnitTranslate: i18n.t('unitKilometers'),
  });

  const technicalCertification = buildArray<DocumentationAttribute>([])
    .add({
      label: i18n.t('status'),
      value: documentation.technicalCert?.status ?? EMPTY_PLACEHOLDER,
    })
    .when(isNotNil(documentation.technicalCert?.numberOfPartsOfRegistrationCertificate), {
      label: i18n.t('numberOfParts'),
      value: documentation.technicalCert?.numberOfPartsOfRegistrationCertificate,
    })
    .when(isNotNil(documentation.technicalCert?.numberOfWwnersInTC), {
      label: i18n.t('numberOfWwnersInTC'),
      value: documentation.technicalCert?.numberOfWwnersInTC,
    })
    .when(isNotNil(documentation.technicalCert?.countryOfFirstRegistration), {
      label: i18n.t('countryOfFirstRegistration'),
      value: documentation.technicalCert?.countryOfFirstRegistration,
    });

  const vehicleUnderFactoryWarranty = buildArray<DocumentationAttribute>([])
    .add({
      label: i18n.t('status'),
      value: documentation.vehicleUnderFactoryWarranty?.status ?? EMPTY_PLACEHOLDER,
    })
    .when(isNotNil(documentation.vehicleUnderFactoryWarranty?.starDate), {
      label: i18n.t('warrantyStartDate'),
      value: getDate(documentation.vehicleUnderFactoryWarranty?.starDate),
    })
    .when(isNotNil(documentation.vehicleUnderFactoryWarranty?.endDate), {
      label: i18n.t('warrantyEndDate'),
      value: getDate(documentation.vehicleUnderFactoryWarranty?.endDate),
    })
    .when(isNotNil(documentation.vehicleUnderFactoryWarranty?.raidUntilEndOfWarranty), {
      label: i18n.t('raidUntilEndOfWarranty'),
      value: getValueWithUnit({
        auditValue: documentation.vehicleUnderFactoryWarranty?.raidUntilEndOfWarranty,
        localeConfig: locale.localeConfig,
        decimals: 0,
        defaultUnitTranslate: i18n.t('unitKilometers'),
      }),
    });

  const operatingInstructions = buildArray<DocumentationAttribute>([])
    .add({
      label: i18n.t('status'),
      value: documentation.operatingInstructions ?? EMPTY_PLACEHOLDER,
    })
    .when(isNotNil(documentation.languageOfInstructions), {
      label: i18n.t('languageOfInstructions'),
      value: documentation.languageOfInstructions,
    });

  return (
    <SectionPrint icon="documentation" heading={i18n.t('documentationHeader')}>
      <VStack spacing={6}>
        <DocumentationItem
          heading={i18n.t('vin')}
          attributes={[{label: 'VIN', value: documentation.vin?.value ?? EMPTY_PLACEHOLDER}]}
          comment={documentation.vin?.comment}
        />
        <Separator />
        <DocumentationItem
          heading={i18n.t('technicalCertificate')}
          comment={documentation.technicalCert?.comment}
          attributes={technicalCertification}
        />
        <Separator />
        <DocumentationItem
          heading={i18n.t('dateOfManufacture')}
          attributes={[
            {
              label: i18n.t('date'),
              value: dateOfManufacture,
            },
          ]}
          comment={documentation.dateOfManufacture?.comment}
        />
        <Separator />
        <DocumentationItem
          heading={i18n.t('firstRegistration')}
          attributes={[
            {
              label: i18n.t('date'),
              value: firstRegistration,
            },
          ]}
          comment={documentation.firstRegistration?.comment}
        />
        <Separator />
        <DocumentationItem
          heading={i18n.t('cocList')}
          attributes={[
            {label: i18n.t('status'), value: documentation.cocListStatus ?? EMPTY_PLACEHOLDER},
          ]}
        />
        <Separator />
        <DocumentationItem
          heading={i18n.t('vehicleUnderFactoryWarranty')}
          attributes={vehicleUnderFactoryWarranty}
        />
        <Separator />
        <DocumentationItem
          heading={i18n.t('serviceBook')}
          attributes={[
            {label: i18n.t('type'), value: documentation.serviceBookType ?? EMPTY_PLACEHOLDER},
            {
              label: i18n.t('status'),
              value: documentation.serviceBookState?.value ?? EMPTY_PLACEHOLDER,
            },
          ]}
          comment={documentation.serviceBookState?.comment}
        />
        <Separator />
        <DocumentationItem
          heading={i18n.t('currentMileage')}
          attributes={[{label: i18n.t('mileage'), value: currentMileage}]}
          comment={documentation.currentMileage?.comment}
        />
        <Separator />
        <DocumentationItem
          heading={i18n.t('operatingInstructions')}
          attributes={operatingInstructions}
        />
        <Separator />
        <Stack direction="row" spacing={10} align="flex-start">
          <Box flex={1} maxWidth={100} width="100%">
            <Heading size={2}>{i18n.t('mandatoryEquipmentAndAccessories')}</Heading>
          </Box>
          <Box flex={1} width="100%">
            <VStack spacing={6}>
              <Box>
                <Heading size={3} alternative>
                  {documentation.mandatoryEquipmentAndAccessories?.value ?? EMPTY_PLACEHOLDER}
                </Heading>
                <Text size="small" color="tertiary">
                  {i18n.t('status')}
                </Text>
              </Box>
              <Show when={isNotNilOrEmpty(documentation.mandatoryEquipmentAndAccessories?.comment)}>
                <Separator />
                <Comment comment={documentation.mandatoryEquipmentAndAccessories?.comment} />
              </Show>
            </VStack>
          </Box>
        </Stack>
        <Show when={isNotNil(documentationSlideshow)}>
          <Separator />
          <CarouselPrint isFilterOfDamageDisabled data={documentationSlideshow} />
        </Show>
      </VStack>
    </SectionPrint>
  );
}

import {useDevice} from 'platform/foundation';

import {useMemo, useState} from 'react';

import {ListFeaturesItemProps} from '../../../components/ListFeatures/components/ListFeaturesItem';
import {SlideImage} from '../../../types/SlideImage';
import {getPositionsWithPadding, Positions} from '../utils/getPositionsWithPadding';
import {useChosenDamage} from './useChosenDamage';

type CarouselData =
  | {
      title: string | null;
      value: string | null;
      comment: string | null;
      location: SlideImage | null;
      slides: SlideImage[];
      point: string;
    }[]
  | null;

export const useGetDamageModalConfiguration = (
  carouselData: CarouselData,
  damagesCarouselRef: React.RefObject<HTMLDivElement | null>,
  area: 'exterior' | 'interior',
  chosenDamage?: {index: number; key: string}
) => {
  const device = useDevice();

  const {handleScrollAfterClickToPoint} = useChosenDamage(damagesCarouselRef);
  const [chosenImageDamageIndex, setChosenImageDamageIndex] = useState<number>();

  const selectedDamageData = carouselData?.reduce<
    (ListFeaturesItemProps & {
      index: number;
      sectionTitle: string;
      onClick: () => void;
      id: string;
    })[]
  >((accumulator, currentItem, currentIndex) => {
    if (
      (chosenDamage?.key === 'roof' && currentItem.point === 'roofWindow') ||
      currentItem.point === chosenDamage?.key
    ) {
      accumulator.push({
        title: currentItem.value || '',
        index: currentIndex,
        variant: 'damaged',
        sectionTitle: currentItem.title || 'dddd',
        onClick: () => {
          setChosenImageDamageIndex(currentIndex);
          handleScrollAfterClickToPoint();
        },
        id: currentItem.point,
      });
    }
    return accumulator;
  }, []);

  const exteriorDamagePositions = useMemo(
    () => getPositionsWithPadding(chosenDamage?.key || 'other', device, damageExteriorModalSide),
    [device, chosenDamage?.key]
  );
  const interiorDamagePositions = useMemo(
    () => getPositionsWithPadding(chosenDamage?.key || 'other', device, damageInteriorModalSides),
    [device, chosenDamage?.key]
  );
  return {
    damageModalPosition: area === 'exterior' ? exteriorDamagePositions : interiorDamagePositions,
    selectedDamageData,
    chosenImageDamageIndex,
  };
};

const damageExteriorModalSide: Record<string, {notebook: Positions; mobile: Positions}> = {
  leftFrontLight: {notebook: 'center', mobile: 'left'},
  rightFrontLight: {notebook: 'center', mobile: 'center'},
  frontBumper: {notebook: 'center', mobile: 'left'},
  leftFrontFender: {notebook: 'center', mobile: 'right'},
  rightFrontFender: {notebook: 'center', mobile: 'center'},
  leftRearFender: {notebook: 'left', mobile: 'right'},
  rightRearFender: {notebook: 'right', mobile: 'right'},
  frontHood: {notebook: 'center', mobile: 'center'},
  leftFrontDoor: {notebook: 'center', mobile: 'right'},
  rightFrontDoor: {notebook: 'center', mobile: 'center'},
  leftBackDoor: {notebook: 'center', mobile: 'center'},
  rightRearDoor: {notebook: 'right', mobile: 'left'},
  leftMirror: {notebook: 'center', mobile: 'left'},
  rightMirror: {notebook: 'center', mobile: 'center'},
  frontWindow: {notebook: 'center', mobile: 'center'},
  roof: {notebook: 'center', mobile: 'center'},
  backDoor: {notebook: 'left', mobile: 'left'},
  backBumper: {notebook: 'left', mobile: 'left'},
  other: {notebook: 'right', mobile: 'right'},
};
const damageInteriorModalSides: Record<string, {notebook: Positions; mobile: Positions}> = {
  ceiling: {notebook: 'center', mobile: 'left'},
  backSeats: {notebook: 'center', mobile: 'center'},
  coverRightPillar: {notebook: 'center', mobile: 'left'},
  coverLeftPillar: {notebook: 'right', mobile: 'right'},
  coverFrontRightDoor: {notebook: 'center', mobile: 'center'},
  coverFrontLeftDoor: {notebook: 'right', mobile: 'right'},
  coverBackLeftDoor: {notebook: 'left', mobile: 'right'},
  coverBackRightDoor: {notebook: 'center', mobile: 'right'},
  passengerSeat: {notebook: 'center', mobile: 'right'},
  driverSeat: {notebook: 'center', mobile: 'center'},
  carpet: {notebook: 'center', mobile: 'center'},
  steeringWheel: {notebook: 'center', mobile: 'center'},
  cockpit: {notebook: 'center', mobile: 'center'},
  other: {notebook: 'right', mobile: 'right'},
};

import {HStack, Show, Space, VStack} from 'platform/foundation';

import {TestIdProps} from 'shared';

import {SectionId, SECTIONS} from '../../../consts/Sections';
import {ButtonIcon} from '../../ButtonIcon/ButtonIcon';
import {MobileDrawer} from '../../MobileDrawer/MobileDrawer';
import {isSectionActive} from '../../Section/utils/getActiveSections';
import {NavigationItem} from './NavigationItem';

export interface MobileNavigationProps extends TestIdProps {
  sections: string[];
  isVisible: boolean;
  onClose: VoidFunction;
  onShareClick: VoidFunction;
  onDownloadClick: VoidFunction;
  isPdfDownloading: boolean;
}

export function MobileNavigation(props: MobileNavigationProps) {
  const navigateToSection = (sectionId: SectionId) => {
    const sectionElement = document.querySelector(`#${sectionId}`);
    if (!sectionElement) {
      return;
    }

    window.scrollTo({
      behavior: 'smooth',
      top:
        sectionElement.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        MOBILE_MENU_HEIGHT,
    });

    props.onClose();
  };

  const sections = SECTIONS.filter((section) => isSectionActive(section.id, props.sections));

  return (
    <MobileDrawer isVisible={props.isVisible}>
      <VStack minHeight="100%" justify="space-between">
        <VStack spacing={1}>
          {sections.map((section) => (
            <NavigationItem
              key={section.id}
              title={section.menuTitle}
              onClick={() => navigateToSection(section.id)}
            />
          ))}
        </VStack>
        <Space vertical={4} fillAvailable />
        <HStack spacing={2}>
          <ButtonIcon
            variant="outline"
            icon="download"
            onClick={props.onDownloadClick}
            isLoading={props.isPdfDownloading}
          />

          <Show when={navigator.share}>
            <ButtonIcon variant="outline" icon="share" onClick={props.onShareClick} />
          </Show>
          <Space horizontal={4} fillAvailable />
          <ButtonIcon onClick={props.onClose} icon="close" variant="outline" />
        </HStack>
      </VStack>
    </MobileDrawer>
  );
}

const MOBILE_MENU_HEIGHT = 30;

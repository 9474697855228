import type {ActionLotties} from './action/actionLotties';

type LottieFile = string;

interface LottiePack {
  [key: string]: LottieFile;
}

type ImportFunction = () => Promise<{lottiesPack: LottiePack}>;

const lottieLoaderFactory =
  <T extends string>(importFn: ImportFunction, icon: T) =>
  () =>
    new Promise<string>((res, rej) =>
      importFn()
        .then((module) => {
          res(module.lottiesPack[icon]);
        })
        .catch(rej)
    );

const loadActionLottie = (icon: ActionLotties) =>
  lottieLoaderFactory(() => import('./action/actionLotties'), icon);

export const themeLottie = {
  'action/done': loadActionLottie('action/done'),
} as const;
